import React, { useCallback, useEffect, useState } from "react";
import {
  CardBody,
  Card,
  CardImg,
  Modal,
  ModalHeader,
  ModalBody,
  Row,
  Col
} from "reactstrap";
import axios from "axios";

const ModalCetakAbsensi = ({ open, toggle, data }) => {
    const token = localStorage.token;
    const [address,setAddress] = useState("");
    const [checkdate,setCheckDate] = useState("");
    const [checktime,setCheckTime] = useState("");
    const [image,setImage] = useState("");
    // const [latitude,setLatitude] = useState("");
    // const [longitude,setLongitude] = useState("");
    const [workplace,setWorkPlace] = useState("");
    const [picture, setPicture] = useState("")
    const [name,setName] = useState("")
    const [status, setStatus] = useState("")

  const getById = useCallback(() => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/absensi-karyawan/get/${data.id}?status-kerja=${data.status_kerja}`, {
        headers,
      })
      .then((data) => {
        setAddress(data.data.response.address);
        setCheckDate(data.data.response.check_date);
        setCheckTime(data.data.response.check_time);
        setImage(data.data.response.image);
        // setLatitude(data.data.response.latitude);
        // setLongitude(data.data.response.longitude);
        setWorkPlace(data.data.response.work_place);
        setPicture(data.data.response.picture);
        setName(data.data.response.name);
        setStatus(data.data.response.status);

      })
      .catch(function (error) {
        console.log(error);
      });
  },[token,data.id,data.status_kerja]);

  useEffect(() => {
    getById();
  }, [getById]);

  // function print() {
  //   var printContents = document.getElementById("targetContent").innerHTML;
  //   var originalContents = document.body.innerHTML;
  //   document.body.innerHTML = printContents;
  //   window.print();
  //   document.body.innerHTML = originalContents;
  // }
  
  return (
    <Modal isOpen={open} toggle={toggle} style={{ minWidth: "50%", top:"-15%" }}>
      <ModalHeader toggle={toggle} align="center">
      </ModalHeader>
      <ModalBody>
        <Card className="card-profile">
          <CardImg
            alt=""
            src={image}
            width="40%"
            top
          />
          <Row className="justify-content-center">
            <Col className="order-lg-3" lg="5">
              <div className="card-profile-image">
                <a href="#pablo" onClick={e => e.preventDefault()}>
                  {picture === "" ? (
                  <img
                    alt="..."
                    className="rounded-circle"
                    src={"https://i.ibb.co/j5qfxy8/NO-IMAGE-AVAILABLE-2.png"}

                  />
                  ):(
                   <img
                    alt="..."
                    className="rounded-circle"
                    src={picture}
                  />
                  )}
                </a>
              </div>
            </Col>
          </Row>
          <CardBody className="pt-0">
            <Row>
              <div className="col ">
                <div className="card-profile-stats d-flex justify-content-center">
                    <br></br>
                    <br></br>
                    <br></br>
                </div>
              </div>
            </Row>
              <div className="col">
                <div className="text-center">
                  <h5 className="h3">
                    {name}
                  </h5>
                  <div className="h4 font-weight-300">
                    <i className="ni location_pin mr-2" />
                    <b>{address}</b>
                  </div>
                </div>
              </div>
            <Row>
              <div className="col">
                <div className="card-profile-stats d-flex justify-content-center">
                  <div>
                    <span className="description">Tanggal</span>
                    <span className="heading">{checkdate}</span>
                  </div>
                  <div>
                    <span className="description">Waktu</span>
                    <span className="heading">{checktime}</span>
                  </div>
                  <div>
                    <span className="description">Lokasi</span>
                    <span className="heading">{workplace}</span>
                  </div>
                  <div>
                    <span className="description">Status</span>
                    <span className="heading">{status}</span>
                  </div>
                </div>
              </div>
            </Row>
          </CardBody>
        </Card>
      </ModalBody>
    </Modal>
  );
};

export default ModalCetakAbsensi;
