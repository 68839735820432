/*eslint-disable*/
import React, { useEffect, useState } from 'react';
import { Card, Button, Row,  Col,CardBody, CardHeader, Container,Badge,Form, FormGroup, Label, Input ,DropdownToggle,UncontrolledDropdown,DropdownMenu,DropdownItem,Modal, ModalHeader, ModalBody} from 'reactstrap';
import { Link } from "react-router-dom";
import axios from 'axios';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import SweetAlert from "react-bootstrap-sweetalert";

const CetakAdjustment = () => {
  const [alert, setAlert] = useState(null);
	const [datalog, setDataLog] = useState([])
	const [rowIndex, setRowIndex] = useState(0);
	const toggle1 = () => setModal1(!modal1);
	const [modal1, setModal1] = useState(false);
  const token = localStorage.token;
  const warehouse = parseInt(localStorage.warehouse);
  const redirectPrefix = `/admin/stock-adjustment/detail/`;
  const [allAdjustment, setAllAdjustment] = useState([]);
  const [status, setStatus] = useState("");
  const [uomCode, setUomCode] = useState("");
  const [description, setDescription] = useState("");
  const [page, setPage] = useState(1);
  const [perPage, setPerpage] = useState(10);
  const [totalItem, setTotalItem] = useState(0);
  const [currentSort, setCurrentSort] = useState("");
 
  let paginationOption = {
    page: page,
    alwaysShowAllBtns: true,
    override: true,
    showTotal: true,
    withFirstAndLast: false,
    sizePerPage: perPage,
    totalSize: totalItem,
    onPageChange: (page) => {
      updateDataTable(page, perPage, currentSort, uomCode, description,status);
    },
    sizePerPageRenderer: () => (
      <div className="dataTables_length" id="datatable-basic_length">
        <label>
          Show{" "}
          {
            <select
              name="datatable-basic_length"
              aria-controls="datatable-basic"
              className="form-control form-control-sm"
              onChange={(e) => {
                updateDataTable(page, e.target.value, currentSort)
              }}
            >
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="25">25</option>
              <option value="50">50</option>
            </select>
          }{" "}
          entries.
        </label>
      </div>
    ),
  }

  const updateDataTable = (page, perPage, sort, uomCode, description,status) => {
    getAdjustment(page, perPage, sort, uomCode, description,status);
    setPage(page);
    setPerpage(perPage);
    setRowIndex((page - 1) * perPage);
    setCurrentSort(sort);
    setUomCode(uomCode);
    setDescription(description);
    setStatus(status)
  }

  const handleTableChange = (type, { sortField, sortOrder }) => {
    if (type === "sort") {
      let sort = `${sortField} ${sortOrder}`
      updateDataTable(page, perPage, sort,  uomCode, description,status)
    }
  }
  
  useEffect(() => {
    getAdjustment(page, perPage, currentSort);
  }, []);

  // fungsi dari ambil data
  const getAdjustment = (page, perPage, currentSort,adj_code='',keterangan='', status_d='') => {
    
    let filter = { 
      page: page, 
      per_page: perPage,
      warehouse_id : parseInt(warehouse),
    };
    if (adj_code !== null) {
      filter = Object.assign(filter, { adj_code: adj_code })
    }
    if (keterangan !== null) {
      filter = Object.assign(filter, { keterangan: keterangan })
    }
    if (status_d !== null) {
      filter = Object.assign(filter, { status_d: status_d })
    }
    const data = filter;
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/adjustment/page`, data, {
        headers,
      })
      .then((data) => {
        setAllAdjustment(data.data.response);
        setPage(data.data.current_page + 1);
        setPerpage(data.data.per_page);
        setTotalItem(data.data.total_item);
      })
      .catch(function (error) {
        // setGagalAlert(error.response.data.message);
        setAllAdjustment(error.response.data.response);
      });
}

  const reset = () => {
    setUomCode("");
    setDescription("");
    setStatus("")
    updateDataTable(1, perPage, currentSort, "", "","");
  }

  const getDataLog = (id) => {
		let filter = { 
			transaction : id,
			features : "TRANSFER2",
			warehouse_id : warehouse
		}
		const data = filter;
		const headers = {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`
		  }
		axios.post(`${process.env.REACT_APP_API_BASE_URL}/riwayat-data/info`, data, { headers
		})
		.then(data => {
			setDataLog(data.data.response);
            setModal1(!modal1);

		})
		  .catch(function (error) {
			setGagalAlert(error.response.data.message);
		  })
	  }

	  const setGagalAlert = (id) => {
		setAlert(
		<SweetAlert
		danger
		showConfirm
		confirmBtnText="Ok"
		title={id}
		onCancel={hideAlert}
		onConfirm={hideAlert}
		/>
		);
		};
		
		const hideAlert = () => {
		  setAlert(null);
		};


  return (
    <div>
      {alert}
      <SimpleHeader name="Histori" parentName="" />
        <Container className="mt--6" fluid>
        <Row>
          <div className="col">
          <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <div style={{ textAlign: 'left' }}>
                    <Link className="btn btn-link" to="/admin/stock-adjustment">
                      <i className="fa fa-arrow-circle-left fa-2x" /> 
                    </Link> 
                  </div>
                </div>
              </CardHeader>
              <CardBody>
                      <Form>
                        <Row md="12">
                          <Col md="3">
                            <FormGroup>
                              <Label htmlFor="exampleFormControlSelect3">Kode</Label>
                              <Input
                                type="text"
                                placeholder="Masukan Kode"
                                value={uomCode}
                                onChange={e => updateDataTable(1, perPage, currentSort, e.target.value, description,status)}
                              />
                            </FormGroup>
                          </Col>
                          <Col md="3">
                            <FormGroup>
                              <Label htmlFor="exampleFormControlSelect3">Keterangan</Label>
                              <Input
                                type="text"
                                placeholder="Masukan Keterangan"
                                value={description}
                                onChange={e => updateDataTable(1, perPage, currentSort, uomCode, e.target.value,status)}
                              />
                            </FormGroup>
                          </Col>
                          <Col md="3">
                            <FormGroup>
                              <Label htmlFor="exampleFormControlSelect3">Status</Label>
                              <Input
                                type="select"
                                placeholder="Masukan Keterangan"
                                value={status}
                                onChange={e => updateDataTable(1, perPage, currentSort, uomCode, description,e.target.value)}
                              >
                                <option value="">Pilih Status</option>
                                <option value={3}>Proses</option>
                                <option value={4}>Tolak</option>
                                <option value={5}>Setuju</option>

                              </Input>
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <Button type="button" onClick={reset} color="secondary">Reset</Button>
                          </Col>
                        </Row>
                      </Form>
                      {allAdjustment === null ? (
                        <div>
                          <ToolkitProvider
                                  rowNumber={rowIndex}
                                  data={""}
                                  keyField="id"
                                  columns={[
                                  {
                                      dataField: "no",
                                      text: "#",
                                      sort: true,
                                      page: 1,
                                      formatter: (cell, row, index) => {
                                      let currentRow = ++index;
                                      return currentRow + rowIndex;
                                      },
                                  },
                                  {
                                      dataField: "",
                                      text: "Tanggal",
                                      sort: true,
                                  },
                                  {
                                      dataField: "",
                                      text: "Kode Adjustment",
                                      sort: true,
                                  },
                                  {
                                      dataField: "",
                                      text: "Keterangan",
                                      sort: true,
                                  },
                                  {
                                      dataField: "",
                                      text: "Status",
                                      sort: true,
                                  },
                                  {
                                      dataField: "",
                                      text: "",
                                  },
                                  ]}
                              >
                                  {(props) => (
                                  <div className="py-4 table-responsive">
                                      <BootstrapTable
                                      remote
                                      {...props.baseProps}
                                      bootstrap4={true}
                                      bordered={false}
                                      hover={true}
                                      onTableChange={handleTableChange}
                                      />
                                  </div>
                                  )}
                          </ToolkitProvider>
                          <CardHeader>
                              <center><h3>DATA NOT FOUND</h3></center>
                          </CardHeader>
                        </div>
                        ):(
                          <ToolkitProvider
                                  rowNumber={rowIndex}
                                  data={allAdjustment}
                                  keyField="id"
                                  columns={[
                                  {
                                      dataField: "no",
                                      text: "#",
                                      sort: true,
                                      page: 1,
                                      formatter: (cell, row, index) => {
                                      let currentRow = ++index;
                                      return currentRow + rowIndex;
                                      },
                                  },
                                  {
                                    dataField: "created_at",
                                    text: "Tanggal",
                                    sort: true,
                                  },
                                  {
                                      dataField: "adj_code",
                                      text: "Kode Adjustment",
                                      sort: true,
                                  },
                                  {
                                      dataField: "keterangan",
                                      text: "Keterangan",
                                      sort: true,
                                  },
                                  {
                                    dataField: "status_d",
                                    text: "Status",
                                    sort: true,
                                    formatter: (cell, row) => {
                                      return row.status_d === 3
                                        ? 
                                          <Badge color="" className="badge-dot mr-4">
                                            <i className="bg-danger" />
                                            Proses
                                          </Badge>
                                        : row.status_d === 4
                                        ? <Badge color="" className="badge-dot mr-4">
                                            <i className="bg-warning" />
                                            Tolak
                                          </Badge>
                                        : <Badge color="" className="badge-dot mr-4">
                                            <i className="bg-success" />
                                            Setuju
                                          </Badge>;
                                    },
                                },
                                {
                                  dataField: "",
                                  text: "",
                                  formatter: (cell, row, index) => {
                                    return row.status_d === 3
                                      ? <UncontrolledDropdown nav>
                                          <DropdownToggle color="danger">
                                              Tindakan
                                            </DropdownToggle>
                                            <DropdownMenu center>
                                                  <Link  to={redirectPrefix + row.id}
                                                    id={"tooltip_" + row.id}>
                                                  <DropdownItem>
                                                    <i className="fas fa-user-edit" /><span>Detail</span>
                                                  </DropdownItem>
                                                  </Link>
                                            </DropdownMenu>
                                        </UncontrolledDropdown>
                                      : row.status_d === 4
                                      ?  <UncontrolledDropdown nav>
                                      <DropdownToggle color="danger">
                                          Tindakan
                                        </DropdownToggle>
                                        <DropdownMenu center>
                                              <Link  to={redirectPrefix + row.id}
                                                id={"tooltip_" + row.id}>
                                              <DropdownItem>
                                                <i className="fas fa-user-edit" /><span>Detail</span>
                                              </DropdownItem>
                                              </Link>
                                        </DropdownMenu>
                                    </UncontrolledDropdown>
                                      : <UncontrolledDropdown nav>
                                      <DropdownToggle color="danger">
                                          Tindakan
                                        </DropdownToggle>
                                        <DropdownMenu center>
                                            <Link  onClick={() => getDataLog(row.id)}>
                                                <DropdownItem>
                                                <i className="fa fa-info-circle" aria-hidden="true"></i> <span>Info</span>
                                                </DropdownItem>
                                            </Link>
                                              <Link  to={redirectPrefix + row.id}
                                                id={"tooltip_" + row.id}>
                                              <DropdownItem>
                                                <i className="fas fa-user-edit" /><span>Detail</span>
                                              </DropdownItem>
                                              </Link>
                                        </DropdownMenu>
                                    </UncontrolledDropdown>
                                  },
                                },
                                  ]}
                              >
                                  {(props) => (
                                  <div className="py-4 table-responsive">
                                      <BootstrapTable
                                      remote
                                      {...props.baseProps}
                                      bootstrap4={true}
                                      bordered={false}
                                      hover={true}
                                      pagination={paginationFactory({ ...paginationOption })}
                                      onTableChange={handleTableChange}
                                      />
                                  </div>
                                  )}
                          </ToolkitProvider>
                        )}
              </CardBody>
            </Card>
          </div>
        </Row>
        </Container>
        <Modal isOpen={modal1} toggle={toggle1} style={{ minWidth: "70%", top: "5%"  }}>
        <ModalHeader toggle={toggle1} className="text-center" align="center"></ModalHeader>
        <ModalBody align="center">
          <h2><b>Log Info</b></h2> 
          <CardBody>
          <ToolkitProvider 
              rowNumber={rowIndex}
              data={datalog}
              keyField="id"
              columns={[
                  {
                  dataField: "no",
                  text: "#",
                  sort: true,
                  page: 1,
                  formatter: (cell, row, index) => {
                      let currentRow = ++index;
                      return currentRow + rowIndex;
                  },
                  },
				  {
					dataField: "api_hit",
					text: "Tanggal Update",
					sort: true,
					},
                  {
                  dataField: "username",
                  text: "PIC",
                  sort: true,
                  },
                  {
                  dataField: "lost_data",
                  text: "Data Lama",
                  sort: true,
                  },
                  {
                  dataField: "show_data",
                  text: "Data Baru",
                  sort: true,
                  },
              ]}
              >
              {(props) => (
                  <div className="py-4 table-responsive">
                  <BootstrapTable
                      remote
                      {...props.baseProps}
                      bootstrap4={true}
                      bordered={false}
                      hover={true}
                  />
                  </div>
              )}
              </ToolkitProvider>
          </CardBody>
          <Button color="secondary" onClick={toggle1}>
            Kembali
          </Button>
        </ModalBody>
      </Modal>
    </div>
  );
}

export default CetakAdjustment;