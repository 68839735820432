/*eslint-disable*/
import React, { useEffect, useState } from "react";
import {Card,Button,Row,Col,CardBody,CardHeader,CardFooter,Table,Container,Form,FormGroup,Label,Input } from "reactstrap";
import { Link,useHistory } from "react-router-dom";
import axios from "axios";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import SweetAlert from "react-bootstrap-sweetalert";
import Halaman404 from "views/404";

const EditOpname = (props ) => {
const [alert, setAlert] = React.useState(null);
const token = localStorage.token;
  const username = localStorage.username;
  const warehouse = localStorage.warehouse;
  let history = useHistory();
  const [keterangan,setKeterangan] = useState("");
  const [savedItems, setSavedItems] = useState([]);
  const [qtyTotal, setTotalQty] = useState(0);
  const [allItemm, setAllItemm] = useState([]);
  const [queryy, setQueryy] = useState("");
  const [area, setArea] = useState("");
  const [codeopname, setCodeOpname] = useState("");
  const [isSearchShoww, setIsSearchShoww] = useState(false);
  const headers = { Authorization: `Bearer ${token}` };
  const [coderak, setCodeRak] = useState("")
  const [coderaks, setCodeRaks] = useState([])
  const allInfo = JSON.parse(localStorage.allinfo);
  const StokOpnames = String(allInfo.privileges.filter((i) => i.privilege_name === "Sub Stok Opname").map((p) => p.read_access));
 
  useEffect(() => {
    getById();
  }, []);

  const getById = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}/opname/get/${props.match.params.id}`,
        { headers }
      )
      .then((data) => {
        setArea(data.data.response.area);
        setKeterangan(data.data.response.keterangan);
        setCodeOpname(data.data.response.opname_code);
        getItemGrup(data.data.response.code_rak)
        getItemDataSaved();
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getItemGrup = (id) => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}/rak/list/${warehouse}`,
        { headers }
      )
      .then((data) => {
        setCodeRaks(data.data.response);
        setCodeRak(id)
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getItemDataSaved = () => {
      axios.post(`${process.env.REACT_APP_API_BASE_URL}/opname/item`, {

        opname_id: props.match.params.id

      }).then(async response => {
          let stateItem = [];
          await Promise.all(response.data.response.map(async (data) => {
              stateItem = [...stateItem, {
                  item_id: data.item_id,
                  item_name:data.item_name,
                  kode_item:data.item_code,
                  satuan:data.satuan,
                  qty: data.qty_fisik,
                  ket:data.ket,
                  data: {
                    item_name: data.item_name,
                    satuan:data.satuan,
                    kode_item:data.item_code,
                    qty: data.qty,
                    ket:data.ket,
                  },
              }];
        }));
        setSavedItems(stateItem);
        
    })
  }

useEffect(() => {
  const getData = setTimeout(async() => {
      if(queryy != ''){
          axios
          .post(`${process.env.REACT_APP_API_BASE_URL}/items-po`, { item_info: queryy , per_page: 10,warehouse_id: parseInt(warehouse)},{ headers })
          .then((res) => {
              setAllItemm(res.data);
              setIsSearchShoww(true);
          })
          .catch(function (error) {
            setAllItemm(error.response.data.response);
            setIsSearchShoww(true);
          });
      }else{
    setAllItemm(null);
      }
    }, 1500)
    return () => clearTimeout(getData)
}, [queryy]);



const handleEditQty = (index, value) => {
  let updateList = savedItems;
  let aqtyTotal = parseInt(updateList[index].harga) + value;
  
  if(!isNaN(value) && value.length > -1){
    updateList[index] = {...updateList[index], qty: value};
    setSavedItems(updateList);
    setTotalQty(qtyTotal + aqtyTotal);
  }else{return false}
}

// const handleEditReason = (index, value) => {
//   let updateList = savedItems;
//   let aqtyTotal = value;
  
//   if(value){
//     updateList[index] = {...updateList[index], reason: value};
//     setSavedItems(updateList);
//     setTotalQty(qtyTotal + aqtyTotal);
//   }else{return false}
// }

const handleEditKeterangan = (index, value) => {
  let updateList = savedItems;
  let aqtyTotal =  value;
  
  if(value){
    updateList[index] = {...updateList[index], ket: value};
    setSavedItems(updateList);
    setTotalQty(qtyTotal + aqtyTotal);
  }else{return false}
}

const saveItemOrUpdate = async (item) => {
  let oldobj = savedItems.find((o) => o.item_id === item.id);

  if (oldobj === undefined) {
    // Menambahkan item baru ke savedItems
    const newSavedItems = [
      ...savedItems,
      {
        item_id: item.id,
        kode_item : item.item_code,
        item_name: item.item_name,
        qty: 0,
        reason: item.reason,
        satuan : item.satuan,
        ket: item.ket,
      },
    ];
    setSavedItems(newSavedItems);

    // Data untuk kedua API
    const codeOpname = codeopname; // Mengambil kode opname dari parameter
    const areacok = area ; // Sesuaikan dengan area yang benar
    const qty_fisik = 0; // Jumlah awal

    try {
      // Panggil API opname/save-item dengan metode GET dan query string
      const response1 = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/opname/save-item`, 
        {
          params: {
            code_rak: coderak,
            item_id: item.id,
            area: areacok,
            code_opname: codeOpname,
            ket: item.ket,
            satuan:item.satuan,
            qty_fisik: qty_fisik,
            warehouse_id: warehouse,
          },
          headers,
        }
      );

      // Panggil API item-stok/save-item dengan metode GET dan query string
      const response2 = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/item-stok/save-item`,
        {
          params: {
            code_rak: coderak,
            item_id: item.id,
            warehouse_id: warehouse,
          },
          headers,
        }
      );

      if (response1.status === 200 && response2.status === 200) {
        // setSuccessAlert("Item berhasil ditambahkan dan disimpan!");
      } else {
        setGagalAlert("Gagal menambahkan item pada salah satu Fungsi.");
      }
    } catch (error) {
      console.error("Error saving item:", error);
      setGagalAlert("Terjadi kesalahan saat menambahkan item.");
    }
  } else {
    // Update qty jika item sudah ada di daftar
    let index = savedItems.findIndex((e) => e.item_id === item.id);
    let updateList = [...savedItems];
    let qtyupdate = parseInt(updateList[index].qty) + 1;
    updateList[index] = { ...updateList[index], qty: qtyupdate };
    setSavedItems(updateList);
  }
};


// const saveItemOrUpdate = (item) => {
//   let oldobj = savedItems.find(o => o.item_id === item.id);
//   if(oldobj===undefined){
//     setSavedItems([...savedItems,{
//       item_id: item.id,
//       item_name: item.item_name,
//       qty: 1,
//       reason : item.reason,
//       ket : item.ket, 
//     }]);
    
//   }else{
//     let index = savedItems.findIndex(e=>e.item_id===item.id);
//     let updateList = savedItems;
//     let qtyupdate = parseInt(updateList[index].qty)+parseInt(1);
//     updateList[index] = {...updateList[index], qty: qtyupdate};
//     setSavedItems(updateList);
//   };
// }


  function CreateData() {
    let dataItems = [];
        savedItems.map((dataItem) => dataItems = [...dataItems, 
            { 
                item_id: dataItem.item_id, 
                qty_fisik: dataItem.qty,
                ket:dataItem.ket,
            }]);
    let data = {
      status_d : 3,
      warehouse_id : parseInt(warehouse),
      username : username,
      code_rak : coderak,
      keterangan: keterangan ,
      items : dataItems
    };
    axios
        .post(
          `${process.env.REACT_APP_API_BASE_URL}/opname/update/${props.match.params.id}`,
          data,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then(function (response) {
          setSuccessAlert(response.data.message)
          setTimeout(() => (history.push("/admin/stock-opname/page")), 1000);
          
        })
        .catch(function (error) {
            setGagalAlert(error.response.data.message);
          });
    };
  
    const setSuccessAlert = (id) => {
      setAlert(
        <SweetAlert
          success
          showConfirm
          confirmBtnText="Ok"
          title={id}
          onCancel={hideAlert}
          onConfirm={hideAlert}
        />
      )
    }
  
  const setGagalAlert = (id) => {
  setAlert(
    <SweetAlert
    danger
    showConfirm
    confirmBtnText="Ok"
    title={id}
    onCancel={hideAlert}
    onConfirm={hideAlert}
    />
  );
  };
  
  const setQuestionAlert = () => {
  setAlert(
    <SweetAlert
    warning
    showCancel
    confirmBtnText="Lanjutkan"
    confirmBtnBsStyle="danger"
    title="Apakah Kamu Yakin?"
    onConfirm={CreateData}
    onCancel={hideAlert}
    focusCancelBtn
    />
  );
  };

  const hideAlert = () => {
    setAlert(null);
  };

 
const handleSubmit = async (e) => {
  e.preventDefault();
  {
    setQueryy("");
    setIsSearchShoww("");
    return true
  }
};

//   const deleteItem = (id) => {
//     let array = [...savedItems];

//     let index = array.findIndex(object => {
//         return object.item_id === id;
//     });

//     if (index !== -1) {
//         array.splice(index, 1);
//         setSavedItems(array);
//     }
// }

const deleteItem = (id) => {
  // Mencari item yang ingin dihapus dalam savedItems
  let itemToDelete = savedItems.find(item => item.item_id === id);

  if (!itemToDelete) {
      return;
  }

  // Mendapatkan parameter yang diperlukan untuk kedua API
  const codeRak = coderak;
  const warehouseId = parseInt(warehouse);
  const codeOpname = codeopname;  // Asumsi ID Opname diambil dari URL params

  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };

  // Panggil API pertama: item-stok/delete-item
  axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/opname/delete-item?code_rak=${codeRak}&item_id=${id}&code_opname=${codeOpname}&warehouse_id=${warehouseId}`,{ headers })
      .then(response => {
        // setSuccessAlert("Item berhasil di Hapus");
          // console.log('Item berhasil dihapus dari rak:', response.data);
          // Panggil API kedua: opname/delete-item
          return axios.get(`${process.env.REACT_APP_API_BASE_URL}/item-stok/delete-item?code_rak=${codeRak}&item_id=${id}&warehouse_id=${warehouseId}`,{ headers });
      

      })
      .then(response => {
          console.log('Item berhasil dihapus dari opname:', response.data);
          // Setelah sukses menghapus, hapus item dari state local
          let updatedItems = savedItems.filter(item => item.item_id !== id);
          setSavedItems(updatedItems);
      })
      .catch(error => {
          // console.error('Terjadi kesalahan saat menghapus item:', error);
          setGagalAlert('Gagal menghapus item. Silakan coba lagi.');
      });
}

  return (
    <>
    {alert}
    {StokOpnames && StokOpnames === "1" ? (
      <div>
    <SimpleHeader name="Edit Opname" parentName="Inventori" />
    <Container className="mt--6" fluid>
        <Row>
          <div className="col">
          <CardBody>
                  <Card className="bg-secondary shadow">
                    <CardHeader className="bg-white border-0">
                      <h3>Edit Opname</h3>
                    </CardHeader>
                    <CardBody>
                      <Row md="12">
                          <Col md="9">
                          <FormGroup row>
                          <Label
                          className="form-control-label"
                            for="exampleEmail"
                            sm={2}
                          >
                            Kode Rak
                          </Label>
                          <Col sm={4}>
                            <Input
                            disabled
                            autoComplete="off"
                              className="form-control-alternative"
                              name="kategory"
                              type="select"
                              value={coderak}
                              onChange={(e) => {
                                setCodeRak(e.target.value);
                              }}
                            >
                              <option value="">Kode Rak</option>
                              {coderaks.map((categorysss, key) => {
                                return (
                                  <option key={key} value={categorysss.rak_code}>
                                    {categorysss.rak_code}
                                  </option>
                                );
                              })}
                              </Input>
                          </Col>
                        </FormGroup>
                            <FormGroup row>
                              <Label
                              className="form-control-label"
                                for="exampleEmail"
                                sm={2}
                              >
                                Keterangan
                              </Label>
                              <Col sm={10}>
                                <Input
                                autoComplete="off"
                                  type="textarea"
                                  name="keterangan"
                                  placeholder="Masukan Keterangan"
                                  style={{
                                    fontSize: 14,
                                    paddingTop: 20,
                                    top: "50%",
                                    height: 117,
                                    resize: "none",
                                  }}
                                  value={keterangan}
                                  onChange={(e) => {
                                    setKeterangan(e.target.value);
                                  }}
                                />
                              </Col>
                            </FormGroup>
                          </Col>
                      </Row>
                    </CardBody>
                  </Card>
                  <Card className="bg-secondary shadow">
                    <CardHeader>
                      <h3>Item </h3>
                    </CardHeader>
                    <CardBody>
                          <Table size="sm" responsive>
                            <thead>
                              <tr>
                                <th><b>Nama Item</b></th>
                                <th><b>Kode Item</b></th>
                                <th><b>Qty</b></th>
                                <th><b>Satuan</b></th>
                                <th><b>Keterangan</b></th>
                              </tr>
                            </thead>
                              <tbody>
                              {
                                savedItems.map((savedItem, key) => {
                                  return (
                                    <tr key={key}>
                                      <td>{savedItem.item_name}</td>
                                      <td>{savedItem.kode_item}</td>
                                      <td>
                                        <Input
                                          className="form-control-alternative"
                                          placeholder="qty"
                                          type="number"
                                          value={savedItem.qty}
                                          onChange={(e) => {
                                              handleEditQty(key, e.target.value);
                                          }}/>
                                      </td>
                                      <td>{savedItem.satuan}</td>
                                      <td>
                                           <Input
                                          className="form-control-alternative"
                                          placeholder="Keterangan"
                                          type="text"
                                          value={savedItem.ket}
                                          onChange={(e) => {
                                            handleEditKeterangan(key, e.target.value);
                                          }}/>
                                      </td>
                                      <td>
                                        <Button color="danger" onClick={() => deleteItem(savedItem.item_id)}><i className="fas fa-trash" /></Button>
                                      </td>
                                    </tr>
                                  )
                                })
                              }
                              <tr>
                                <td>
                                  <><Form onSubmit={handleSubmit}>
                                          <Input 
                                            autoComplete="off"
                                            placeholder="Masukan Item Manual"
                                            type="search"
                                            value={queryy}
                                            onChange={(e) => setQueryy(e.target.value)}
                                          />
                                        {isSearchShoww && queryy && (
                                        <Card className="position-sticky boxShadow" style={{ maxHeight: "15.5rem", overflowY: "auto", paddingTop: "1rem", position: "relative" }}>
                                          <div style={{ position: "absolute", top: "2.5px", right: "1rem", cursor: "pointer", fontSize: "1rem" }}>
                                            <i className="fas fa-window-close text-danger" onClick={() => setIsSearchShoww(false)}></i>
                                          </div>
                                          {allItemm?.response ? (
                                            allItemm.response.map((item) => (
                                              <CardBody key={item.id} style={{ minHeight: "5rem", padding: "1rem" }} className="bgSearch" onClick={() => {saveItemOrUpdate(item);setQueryy('');setIsSearchShoww(false);}}>
                                                <div>
                                                  <b>Nama item:</b> {item.item_name}
                                                </div>
                                                <div>
                                                  <b>Kode Item:</b> {item.item_code}
                                                </div>
                                              </CardBody>
                                            ))
                                          ) : (
                                            <div className="text-center mb-3 text-danger">Item "{queryy}" tidak ada bosku!</div>
                                          )}
                                        </Card>
                                      )}
                                  </Form></>
                                  </td>
                                 </tr>
                              </tbody>
                          </Table>
                        </CardBody>
                <CardFooter>
                      <Link className="btn btn-info" to="/admin/stock-opname/page">
                        Kembali
                      </Link>
                      <Button color="danger" onClick={setQuestionAlert}>
                            Simpan
                        </Button>
                </CardFooter>
              </Card>
              </CardBody>
          </div>
        </Row>
    </Container>  
    </div>
    ):(
      <Halaman404 />
    )}
    </>
  );
}

export default EditOpname;