/*eslint-disable*/
import React, { useEffect, useState,useRef } from "react";
import {Card,Button,Row,Col,CardBody,CardHeader,CardFooter,Table,Container,Form,FormGroup,Label,Input,FormFeedback,Modal, ModalHeader, ModalBody} from "reactstrap";
import { Link,useHistory } from "react-router-dom";
import axios from "axios";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import Select2 from "react-select2-wrapper";
import SweetAlert from "react-bootstrap-sweetalert";
import Halaman404 from "views/404";
import CurrencyInput from 'react-currency-input-field';

const CreateSalesOrderPusat = () => {
  const [alert, setAlert] = React.useState(null);
  const [reasons, setReasons] = useState([])
  const token = localStorage.token;
  const username = localStorage.username;
  const warehouse = localStorage.warehouse;
  let history = useHistory();
  const [customers, setCustomers] = useState([]); 
  const [customer, setCustomer] = useState("");
  const [typereq, setTypeReq] = useState([]);
  const [pengiriman, setPengiriman] = useState(1);
  const [payment_method,setPaymentMethod] = useState(1);
  const [keterangan, setKeterangan] = useState("");
  const [allJangkaWaktu, setAllJangkaWaktu] = useState([]);
  const [jangkaWaktu,setJangkaWaktu] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [savedItems, setSavedItems] = useState([]);
  const [coderfq, setCodeRfq] = useState("");
  const [isSearchShoww, setIsSearchShoww] = useState(false);
	const [allItemm, setAllItemm] = useState([]);
	const [queryy, setQueryy] = useState("");
  const [ppn, setPPN] = useState(0);
  const [totaldiskon, setTotalDiskon] = useState(0);
  const [grandtotal, setGrandTotal] = useState(0);
  const [diskonglobalnominal, setDiskonGlobalNominal] = useState(0);
  const [diskonglobalpersen, setDiskonGlobalPersen] = useState(0);
  const [ongkir, setOngkir] = useState(0);
  const [pajak, setPajak] = useState(1);
  const [allpajak, setAllPajak] = useState([]);
  const [lainnya, setLainnya] = useState(0);
  const [alamat, setAlamat] = useState("");
  const [ongkir1, setOngkir1] = useState(0);
	const [ppnnew, setPPNNEW] = useState(0);
	const [diskonglobal, setDiskonGLobal] = useState(0);
  const [totalPrice1, setTotalPrice1] = useState(0);
  const a = 0;
	const b = 0;
	const c = 0;
  const [dp, setDp] = useState(0);
  const [dp1, setDp1] = useState(0);
  const [diskonglobalnominal1,setDiskonGlobalNominal1] = useState(0)
	const [totaldiskon2, setTotalDiskon2] = useState(0);
	const [totaldiskon3, setTotalDiskon3] = useState(0);
	const [editable, setEditable] = useState(false);
	const [isConfEditableOpen, setIsConfEditableOpen] = useState(false);
	const [confPassEditable, setConfPassEditable] = useState('');
	const [qtyTotal, setTotalQty] = useState(0);
	const [diskontotal, setDiskonTotal] = useState(0);
  const [nameotorisasi, setNameOtorisasi] = useState("");
	const [nameotorisasiError, setNameOtorisasiError] = useState(null);
	const [keterangan5, setKeterangan5] = useState("");
	const [keteranganError, setKeteranganError] = useState(null);
  const [isSearchShow, setIsSearchShow] = useState(false);
  const headers = { Authorization: `Bearer ${token}`};
  const [allItem, setAllItem] = useState([]);
  const [query, setQuery] = useState("");
  const [bankcustomer, setBankCustomer] = useState("")
  const [bankcustomers, setBankCustomers] = useState([])
  const [warehousepusat, setWarehousePusat] = useState("");
  const [warehousepusats, setWarehousePusats] = useState([]);
  const [qtyError, setQtyError] = useState(null);
  const allInfo = JSON.parse(localStorage.allinfo);
  const [itemIDActive, setItemIDActive] = useState([])
	const salesOrderPusat = String(allInfo.privileges.filter((i) => i.privilege_name === "Sub SO Pusat").map((p) => p.read_access));
  const EditData = async (e) => {setTimeout(handleClick, 1000);};
	function handleClick() {cekPromo.current.onClick();}
	const cekPromo = useRef();

  const validateForm1 = () => {
      let error = false;
      savedItems.map((savedItem, key) => { 
        if (savedItem.qty > savedItem.qtysisa) {
          setQtyError("invalid");
          error = true;
        }
      })
      return error;
  };

  const handleQtySisa = (e) => {
    e.preventDefault();
    if (!validateForm1()) {
        setQuestionAlert()
    }
  }

  useEffect(() => {
    getCustomer();
    getPajaks()
    getJangkaWaktu();
    getReason()
    getPusat();

  }, []);

  const getPusat = () => {
    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    axios.get(`${process.env.REACT_APP_API_BASE_URL}/warehouse/list/all`, { headers
    })
    .then(data => {
      setWarehousePusats(data.data.response);
    })
      .catch(function (error) {
        console.log(error)
      })
  }

  const searchShow = (item) => {
    AmbilDataCok(item.id);
    setIsSearchShow(false);
  };

  const getReason = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .get(
      `${process.env.REACT_APP_API_BASE_URL}/reason/list?type=2`,
      { headers }
      )
      .then((data) => {
      setReasons(data.data.response);
      })
      .catch(function (error) {
      console.log(error);
      });
    };

  useEffect(() => {
    const getData = setTimeout(async() => {
      if(query != ""){
        axios
        .post(`${process.env.REACT_APP_API_BASE_URL}/rfq-so/page`, {  page: 1,per_page: 10,status_rfq : 5,rfq_code: query,active_flag: 1,warehouse_id : parseInt(warehouse),},{ headers })
        .then((res) => {
          setAllItem(res.data);
          setIsSearchShow(true);
        })
        .catch(function (error) {
              setGagalAlert(error.response.data.message);
        });
      }}, 1000)
      return () => clearTimeout(getData)
  }, [query]);
  
  const AmbilDataCok = (id) => {
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
      axios
        .get(
          `${process.env.REACT_APP_API_BASE_URL}/rfq-so/get/${id}`,
          { headers }
        )
        .then((data) => {
                  getCustomer(data.data.response.customer_id);
                  setWarehousePusat(data.data.response.warehouse_id);
                  getbankCustomer(data.data.response.customer_id);
                  setBankCustomer(data.data.response.account_number);
                  getPajaks(data.data.response.pajak_id);
                  getByPajak(data.data.response.pajak_id);
                  getItemDataSaved(data.data.response.rfq_code);
                  setCodeRfq(data.data.response.rfq_code);
                  setTypeReq(data.data.response.type);
                  setDp(data.data.response.um_nominal)
                  // setPengiriman(data.data.response.pengiriman);
                  setKeterangan(data.data.response.keterangan);
                  setTotalPrice(data.data.response.price_real);
                  setTotalQty(data.data.response.price_real);
                  setDiskonTotal(data.data.response.diskon_total);
                  setJangkaWaktu(data.data.response.jangka_waktu);
                  setOngkir(data.data.response.ongkir);
                  setLainnya(data.data.response.lainnya);
                  // setPaymentMethod(data.data.response.payment_method);
                  setDiskonGlobalNominal(data.data.response.diskon_global_nominal);
                  setDiskonGlobalPersen(data.data.response.diskon_global_persen);
                  setAlamat(data.data.response.manual_address);
        })
        .catch(function (error) {
          console.log(error);
        });
    };

  const getItemDataSaved = (id) => {
    axios.post(`${process.env.REACT_APP_API_BASE_URL}/rfq-so/item-by-code`, {

        rfq_code: id

    }).then(async response => {
        let stateItem = [];

        await Promise.all(response.data.response.map(async (data) => {
            stateItem = [...stateItem, {
                item_id: data.item_id,
                item_name:data.item_name,
                item_code: data.item_code,
                satuan: data.satuan,
                qty: data.qty,
                qtysisa: data.qty,
                harga: data.harga,
                diskon_persen: data.diskon_persen,
                diskon_nominal: data.diskon_nominal,
                data: {
                    item_name: data.item_name,
                    harga: data.harga,
                    qtysisa: data.qty,
                    diskon_persen: data.diskon_persen,
                    diskon_nominal: data.diskon_nominal,
                    qty: data.qty,
                },
            }];
        }));
        setSavedItems(stateItem);
    })
}

function CekItemCabang() {
  let dataItems = [];
  savedItems.map((dataItem) => dataItems = [...dataItems, 
      { 
          item_id: dataItem.item_id,
          qty: dataItem.qty, 
          harga: dataItem.harga,
          diskon_persen: dataItem.diskon_persen,
          diskon_nominal: dataItem.diskon_nominal
      }]);
	let data = {
		warehouse_id: warehousepusat,
    items : dataItems
	};
	axios
		.post(`${process.env.REACT_APP_API_BASE_URL}/sales-order/ganti-cabang-item`, data, {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		}).then(async response => {
			let stateItem = [];
			await Promise.all(response.data.response.map(async (data) => {
				stateItem = [...stateItem, {
					item_id: data.item_id,
					item_name:data.item_name,
					item_code : data.item_code,
					satuan : data.satuan,
					code_promo: data.code_promo,
					type : data.type,
					qty: data.qty,
					parent_child : data.parent_child,
					harga: data.harga,
					diskon_persen: data.diskon_persen,
					diskon_nominal: data.diskon_nominal,
					data: {
						item_name: data.item_name,
						harga: data.harga,
						code_promo: data.code_promo,
						type : data.type,
						parent_child : data.parent_child,
						diskon_persen: data.diskon_persen,
						diskon_nominal: data.diskon_nominal,
						qty: data.qty,
					},
				}];
			}));
			setSavedItems(stateItem);
		})
	}

	const validateForm = () => {
		let error = false;
		if (nameotorisasi === "") {
			setNameOtorisasiError("invalid");
		  error = true;
		}
		if (keterangan5 === "") {
			setKeteranganError("invalid");
		  error = true;
		}
		return error;
	  };

	  const handleSubmit4 = (e) => {
		e.preventDefault();
		if (!validateForm()) {
			cekOtoritas();
		}
	  }

    const cekOtoritas = async () => {
      let data = {
        // code_transaction: code,
        warehouse_id: parseInt(warehouse),
        username: nameotorisasi,
        password_otorisasi: confPassEditable,
        note : keterangan5
        }
        await axios.post(`${process.env.REACT_APP_API_BASE_URL}/password-operasional/so`, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        }
        })
        .then(function (response) {
        setEditable(true);
        setIsConfEditableOpen(false);
        setConfPassEditable('');
        setNameOtorisasi('');
        setKeterangan5('')
        setSuccessAlert(response.data.message);
        let oldobj = savedItems.find(o => o.item_id === itemIDActive);
        if(oldobj===undefined){}else{
          let index = savedItems.findIndex(e=>e.item_id===itemIDActive);
          let updateList = savedItems;
          updateList[index] = {...updateList[index], edit_item: true};
          setSavedItems(updateList);
        }
      })
      .catch(function (error) {
      if(error.response.data.status == 400){
        setEditable(false);setIsConfEditableOpen(true);setConfPassEditable('');setNameOtorisasi('');setKeterangan5('')
        setGagalAlert(error.response.data.message);
      }
      });
  }

  const simpanEdit = async () => {
    let oldobj = savedItems.find(o => o.item_id === itemIDActive);
    if(oldobj===undefined){}else{
      let index = savedItems.findIndex(e=>e.item_id===itemIDActive);
      let updateList = savedItems;
      updateList[index] = {...updateList[index], edit_item: false};
      setSavedItems(updateList);
    }
  }

	  // const cekOtoritas = async () => {
    //   let data = {
    //     // code_transaction: codenota,
    //     warehouse_id: parseInt(warehouse),
    //     username: nameotorisasi,
    //     password_otorisasi: confPassEditable,
    //     note : keterangan5
    //     }
    //     await axios.post(`${process.env.REACT_APP_API_BASE_URL}/password-operasional/so`, data, {
    //     headers: {
    //       Authorization: `Bearer ${token}`,
    //     }
    //     })
    //     .then(function (response) {
    //     setEditable(true);setIsConfEditableOpen(false);setConfPassEditable('');setNameOtorisasi('');setKeterangan5('')
    //     setSuccessAlert(response.data.message);  
    //   })
    //     .catch(function (error) {
    //     if(error.response.data.status == 400){
    //       setEditable(false);setIsConfEditableOpen(true);setConfPassEditable('');setNameOtorisasi('');setKeterangan5('')
    //       setGagalAlert(error.response.data.message);
    //     }
    //     });
    // };

  // useEffect(() => {
	// 	setDiskonGlobalNominal1(diskonglobalnominal);
	// }, [diskonglobalnominal]);

  //   // diskon bayangan
  //   useEffect(() => {
	// 	setTotalDiskon(diskonglobalnominal1);
	// }, [diskonglobalnominal1]);

	// useEffect(() => {
	// 	setTotalDiskon3(diskontotal-a);
	// }, [diskontotal, a]);

  //   //diskon tampil
  //   useEffect(() => {
	// 	setTotalDiskon2(totaldiskon - a);
	// }, [totaldiskon3, totaldiskon, a]);

	// //ongkir tampil
	// useEffect(() => {
	// 	setOngkir1(ongkir - b);
	// }, [ongkir, b]);


  //   useEffect(() => {
	// 	setTotalPrice1(qtyTotal);
	// }, [qtyTotal]);


  //   // diskonglobalnominal dan persen
  //   useEffect(() => {
  //     diskonglobalnominal && diskonglobal === "diskonglobalnominal" && setDiskonGlobalPersen((diskonglobalnominal / qtyTotal ) * 100);
  //     diskonglobalpersen && diskonglobal === "diskonglobalpersen"  && setDiskonGlobalNominal((qtyTotal) * (diskonglobalpersen/100));
  //   }, [diskonglobalnominal, qtyTotal, diskonglobalpersen,diskontotal,diskonglobalpersen]);

  //   // hasil nominal dari PPN
  //   useEffect(() => {
  //       setPPNNEW( (qtyTotal - totaldiskon) * (ppn / 100));
  //  }, [qtyTotal,totaldiskon,ppn]);

  //   // hasil grandtotal
	// useEffect(() => {
	// 	setGrandTotal(( qtyTotal - totaldiskon2) + ppnnew + ongkir1- a );
	// }, [qtyTotal, totaldiskon2,ppnnew, ongkir1, a]);

  // useEffect(() => {
  //   setDiskonGlobalNominal1(diskonglobalnominal);
  // }, [diskonglobalnominal]);

  //   // diskon bayangan
  //   useEffect(() => {
  //   setTotalDiskon(diskonglobalnominal1);
  // }, [diskonglobalnominal1]);

  // useEffect(() => {
  //   setTotalDiskon3(diskontotal-a);
  // }, [diskontotal, a]);

  //   //diskon tampil
  //   useEffect(() => {
  //   setTotalDiskon2(totaldiskon - a);
  // }, [totaldiskon3, totaldiskon, a]);

  // //ongkir tampil
  // useEffect(() => {
  //   setOngkir1(ongkir - b);
  // }, [ongkir, b]);


  //   useEffect(() => {
  //   setTotalPrice1(qtyTotal - ppnnew);
  // }, [qtyTotal, ppnnew]);


  //   // diskonglobalnominal dan persen
  //   useEffect(() => {
  //     diskonglobalnominal && diskonglobal === "diskonglobalnominal" && setDiskonGlobalPersen((diskonglobalnominal / qtyTotal ) * 100);
  //     diskonglobalpersen && diskonglobal === "diskonglobalpersen"  && setDiskonGlobalNominal((qtyTotal) * (diskonglobalpersen/100));
  //   }, [diskonglobalnominal, qtyTotal, diskonglobal, diskonglobalpersen,diskontotal,diskonglobalpersen]);

  //   // hasil nominal dari PPN
  //   useEffect(() => {
  //     setPPNNEW( (qtyTotal - totaldiskon) - ((qtyTotal - totaldiskon) / ((ppn / 100) + (100 / 100))));
  //   }, [qtyTotal,totaldiskon,ppn]);

  //   // hasil grandtotal
  // useEffect(() => {
  //   setGrandTotal(( totalPrice1 - totaldiskon2) + ppnnew + ongkir1- a );
  // }, [totalPrice1,qtyTotal, totaldiskon2,ppnnew, ongkir1, a]);

  useEffect(() => {
    setDiskonGlobalNominal1(diskonglobalnominal);
  }, [diskonglobalnominal]);

    // diskon bayangan
    useEffect(() => {
    setTotalDiskon(diskonglobalnominal1);
  }, [diskonglobalnominal1]);

  useEffect(() => {
    setTotalDiskon3(diskontotal-a);
  }, [diskontotal, a]);

    //diskon tampil
    useEffect(() => {
    setTotalDiskon2((totaldiskon)/((ppn/100)+1));
  }, [totaldiskon3, totaldiskon, ppn , a]);

   //uang muka tampil
   useEffect(() => {
    setDp1(dp - c);
  }, [dp, c]);

  //ongkir tampil
  useEffect(() => {
    setOngkir1(ongkir - b);
  }, [ongkir, b]);

    useEffect(() => {
    setTotalPrice1((qtyTotal)/((ppn/100)+1));
  }, [qtyTotal, ppnnew,ppn]);


    // diskonglobalnominal dan persen
    useEffect(() => {
      diskonglobalnominal && diskonglobal === "diskonglobalnominal" && setDiskonGlobalPersen((diskonglobalnominal / qtyTotal ) * 100);
      diskonglobalpersen && diskonglobal === "diskonglobalpersen"  && setDiskonGlobalNominal((qtyTotal) * (diskonglobalpersen/100));
    }, [diskonglobalnominal, qtyTotal, diskonglobal, diskonglobalpersen,diskontotal,diskonglobalpersen]);

    // hasil nominal dari PPN
    useEffect(() => {
      setPPNNEW( (qtyTotal - totaldiskon) - ((qtyTotal - totaldiskon) / ((ppn / 100) + (100 / 100))));
    }, [qtyTotal,totaldiskon,ppn]);

    // hasil grandtotal
  useEffect(() => {
    setGrandTotal(( totalPrice1 - totaldiskon2) + ppnnew - dp1 + ongkir1- a );
  }, [totalPrice1,qtyTotal, totaldiskon2, ppnnew,dp1, ongkir1, a]);

 
//   useEffect(() => {
//     getById();
// }, [pajak]);

const getByPajak = (id) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };
  axios
    .get(
      `${process.env.REACT_APP_API_BASE_URL}/pajak/${id}`,
      { headers }
    )
    .then((data) => {;
      setPPN(data.data.response.persentase);
    })
    .catch(function (error) {
      console.log(error);
    });
};

useEffect(() => {
  const getData = setTimeout(async() => {
      if(queryy != ''){
          axios
          .post(`${process.env.REACT_APP_API_BASE_URL}/items-so-proyek`, { item_info: queryy , per_page: 10, warehouse_id: parseInt(warehousepusat)},{ headers })
          .then((res) => {
              setAllItemm(res.data);
              setIsSearchShoww(true);
          })
          .catch(function (error) {
          });
      }else{
    setAllItemm(null);
      }
    }, 1000)
    return () => clearTimeout(getData)
}, [queryy]);


const handleEditQty = (index, value) => {
  let updateList = savedItems;
  let minTotal = (parseInt(updateList[index].harga)- parseInt(updateList[index].diskon_nominal)) * parseInt(updateList[index].qty) ;
  let plusTotal = (parseInt(updateList[index].harga)- parseInt(updateList[index].diskon_nominal)) * value ;
  let aqtyTotala = (parseInt(updateList[index].harga) - parseInt(updateList[index].diskon_nominal))* value ;
  let aqtyTotal = (parseInt(updateList[index].harga) - parseInt(updateList[index].diskon_nominal)) * parseInt(updateList[index].qty) ;
  let diskonnominal1 = parseInt(updateList[index].diskon_nominal) * value ;
  let diskonnominal2 = parseInt(updateList[index].qty) * parseInt(updateList[index].diskon_nominal);
  
  if(!isNaN(value)&&value.length>0){
    updateList[index] = {...updateList[index], qty: value};
    setSavedItems(updateList);
    setTotalPrice(totalPrice+plusTotal-minTotal);
    setTotalQty(qtyTotal + aqtyTotala - aqtyTotal);
    // setDiskonTotal(diskontotal + diskonnominal1 - diskonnominal2)
  }else{return false}
}

const handleEditHarga = (index, value) => {
  if(!isNaN(value)&&value.length>0){
    let updateList = savedItems;
    let minTotal = (parseInt(updateList[index].harga)- parseInt(updateList[index].diskon_nominal)) * parseInt(updateList[index].qty) ;
    let aqtyTotal = (parseInt(updateList[index].harga) - parseInt(updateList[index].diskon_nominal))* parseInt(updateList[index].qty)  ;
    updateList[index] = {...updateList[index], harga: value};
    setSavedItems(updateList);
    let plusTotal = parseInt(updateList[index].qty) * (value - parseInt(updateList[index].diskon_nominal));
    setTotalPrice(totalPrice + plusTotal - minTotal);
    let aqtyTotala = parseInt(updateList[index].qty) * (value  - parseInt(updateList[index].diskon_nominal));
    setTotalQty(qtyTotal + aqtyTotala - aqtyTotal);
  }else{return false}
}

const handleEditDiskonNominal = (index, value) => {
  if(!isNaN(value)&&value.length>0){
    let updateList = savedItems;
    let diskonnominal1 = parseInt(updateList[index].qty) * value ;
    let diskonnominal2 = parseInt(updateList[index].qty) * parseInt(updateList[index].diskon_nominal);
    let persentasediskonnya = value / parseInt(updateList[index].harga) * 100;
    let aqtyTotal = (parseInt(updateList[index].harga)- parseInt(updateList[index].diskon_nominal)) * parseInt(updateList[index].qty) ;
    let minTotal = (parseInt(updateList[index].harga)- parseInt(updateList[index].diskon_nominal)) * parseInt(updateList[index].qty) ;
    updateList[index] = {...updateList[index], diskon_nominal: value, diskon_persen: persentasediskonnya};
    setSavedItems(updateList);
    let plusTotal = parseInt(updateList[index].qty) * (parseInt(updateList[index].harga) - parseInt(updateList[index].diskon_nominal));
    setTotalPrice(totalPrice+plusTotal-minTotal);
    let aqtyTotala = (parseInt(updateList[index].harga) - parseInt(updateList[index].diskon_nominal)) * parseInt(updateList[index].qty );
    setTotalQty(qtyTotal + aqtyTotala - aqtyTotal);
    // setDiskonTotal(diskontotal + diskonnominal1 - diskonnominal2)
  }else{return false}
}

const handleEditDiskonPersen = (index, value) => {
  if(!isNaN(value)&&value.length>0){
    let updateList = savedItems;
    let  nominaldiskonnya = parseInt(updateList[index].harga) * value/100;
    let minTotal = (parseInt(updateList[index].harga) - parseInt(updateList[index].diskon_nominal)) * parseInt(updateList[index].qty) ;
    let aqtyTotal = (parseInt(updateList[index].harga)- parseInt(updateList[index].diskon_nominal)) * parseInt(updateList[index].qty) ;
    updateList[index] = {...updateList[index], diskon_nominal: nominaldiskonnya, diskon_persen: value};
    setSavedItems(updateList);
    let plusTotal = (parseInt(updateList[index].qty)- parseInt(updateList[index].diskon_nominal)) * parseInt(updateList[index].harga) ;
    setTotalPrice(totalPrice+plusTotal-minTotal);
    let qatyTotal = (parseInt(updateList[index].harga) - parseInt(updateList[index].diskon_nominal)) * parseInt(updateList[index].qty)  ;
    setTotalQty(qtyTotal + qatyTotal - aqtyTotal);
    let diskonnominal1 = parseInt(updateList[index].qty) * value ;
    let diskonnominal2 = parseInt(updateList[index].qty) * parseInt(updateList[index].diskon_nominal);
    // setDiskonTotal(diskontotal + diskonnominal1 - diskonnominal2 )
  }else{return false}
}

// save
const saveItemOrUpdate = (item) => {
  let oldobj = savedItems.find(o => o.item_id === item.id);
  if(oldobj===undefined){
    setSavedItems([...savedItems,{
      item_id: item.id,
      item_name: item.item_name,
      item_code:item.item_code,
      satuan : item.satuan,
      barcode: item.barcode,
      qty: 1,
      diskon_nominal : item.diskon_nominal,
      diskon_persen:  item.diskon_persen,
      harga: item.price,
    }]);
    return setTotalPrice(totalPrice + (item.price - item.diskon_nominal) * 1),
    setTotalQty(qtyTotal + (item.price - item.diskon_nominal) * 1)
    // setDiskonTotal(diskontotal + diskonglobalnominal * 1)
  }else{
  let index = savedItems.findIndex(e=>e.item_id===item.id);
  let updateList = savedItems;
  let minTotal = (parseInt(updateList[index].harga) - parseInt(updateList[index].diskon_nominal)) * parseInt(updateList[index].qty) ;
  let qtyTotall = parseInt(updateList[index].harga) * parseInt(updateList[index].qty);
  let diskontotall = parseInt(updateList[index].diskon_nominal) * parseInt(updateList[index].qty);
  let qtyupdate = parseInt(updateList[index].qty)+parseInt(1);
  updateList[index] = {...updateList[index], qty: qtyupdate};
  setSavedItems(updateList);
  let fixPrice = totalPrice - minTotal;
  // let fixQty = qtyTotal - qtyTotall ;
  let fixQty = qtyTotal - minTotal ;
  let fixDiskon = diskontotal - diskontotall ;
  return setTotalPrice(fixPrice + (parseInt(updateList[index].harga) - parseInt(updateList[index].diskon_nominal)) * qtyupdate),
  setTotalQty(fixQty + (parseInt(updateList[index].harga) - parseInt(updateList[index].diskon_nominal))  * qtyupdate),
  setDiskonTotal(fixDiskon + parseInt(updateList[index].diskon_nominal) * qtyupdate)
  };
}

const handleSubmit = async (e) => {
  e.preventDefault();
  {
    setQueryy("");
    setIsSearchShoww("");
    return true
  }
};

  const deleteItem = (id) => {
    let array = [...savedItems];

    let index = array.findIndex(object => {
      return object.item_id === id;
    });

    if (index !== -1) {
      setTotalPrice(totalPrice - (array[index].harga - array[index].diskon_nominal) * array[index].qty)  ;
      setTotalQty(qtyTotal - (array[index].harga - array[index].diskon_nominal) * array[index].qty) ;
      setDiskonTotal(diskontotal - array[index].diskon_nominal * array[index].qty);
      array.splice(index, 1);
      setSavedItems(array);
    }
  }

  

  const getJangkaWaktu = (id) => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/jwkredit/list`, { headers })
      .then((data) => {
        setAllJangkaWaktu(data.data.response);
        setJangkaWaktu(id);
      })
      .catch(function (error) {
        console.log(error);
      });
  };


  const getPajaks = (id) => {
		const headers = {
			"Content-Type": "application/json",
			Authorization: `bearer ${token}`,
		};
		axios
			.get(`${process.env.REACT_APP_API_BASE_URL}/pajak/list`, { headers })
			.then((data) => {
				setAllPajak(data.data.response);
				setPajak(id);
			})
			.catch(function (error) {
				console.log(error);
			});
	};

  const getCustomer = (id) => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}/customer/list`,
        { headers }
      )
      .then((data) => {
        setCustomers(data.data.response);
        setCustomer(id);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getbankCustomer = (id) => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/bank/get-by-customer/${id}`, { headers })
      .then((data) => {
        setBankCustomers(data.data.response);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getAlamat = (id) => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}/customer/get/${id}`,
        { headers }
      )
      .then((data) => {
          setAlamat(data.data.response.address)
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handleSubmit1 = () => {
    hideAlert();
    setTimeout(() => {CreateData()}, 1000);
  };

  function CreateData() {
    let dataItems = [];
        savedItems.map((dataItem) => dataItems = [...dataItems, 
            { 
                item_id: dataItem.item_id,
                qty: dataItem.qty, 
                harga: dataItem.harga,
                diskon_persen: dataItem.diskon_persen,
                diskon_nominal: dataItem.diskon_nominal
            }]);
    let data = {
      warehouse_id : warehousepusat,
      username : username,
      pajak_id: parseInt(pajak),
      code_rfq: coderfq,
      customer_id: parseInt(customer),
      type : parseInt(typereq),
      pengiriman: pengiriman,
      payment_method: payment_method,
      account_number: bankcustomer,
      um_nominal : parseFloat(dp),
      ongkir: parseFloat(ongkir),
      lainnya:parseFloat(lainnya),
      manual_address: alamat,
      jangka_waktu:parseInt(jangkaWaktu),
      keterangan: keterangan ,
      diskon_global_nominal: parseFloat(diskonglobalnominal),
      diskon_global_persen : parseFloat(diskonglobalpersen),
      items : dataItems
    };
    axios
        .post(
          `${process.env.REACT_APP_API_BASE_URL}/sales-order/save`,
          data,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then(function (response) {
          setSuccessAlert(response.data.message);
          setTimeout(() => (history.push("/admin/pusat/sales-order/page")), 1000);
        })
        .catch(function (error) {
              setGagalAlert(error.response.data.message);
        });
  }

  const setSuccessAlert = (id) => {
    setAlert(
      <SweetAlert
        success
        showConfirm
        confirmBtnText="Ok"
        title={id}
        timeout={1000}
        onCancel={hideAlert}
        onConfirm={hideAlert}
      />
    )
  }

const setGagalAlert = (id) => {
setAlert(
  <SweetAlert
  danger
  showConfirm
  confirmBtnText="Ok"
  title={id}
  onCancel={hideAlert}
  onConfirm={hideAlert}
  />
);
};

const setQuestionAlert = () => {
setAlert(
  <SweetAlert
  warning
  showCancel
  confirmBtnText="Lanjutkan"
  confirmBtnBsStyle="danger"
  title="Apakah Kamu Yakin?"
  onConfirm={handleSubmit1}
  onCancel={hideAlert}
  focusCancelBtn
  />
);
};

  const hideAlert = () => {
    setAlert(null);
  };


const formatRupiah = (money) => {
  return new Intl.NumberFormat('id-ID',
      { style: 'currency', currency: 'IDR', minimumFractionDigits: 0 }
  ).format(money);
}

const HargaDiskonPersen = (e) => {
  const value = e === undefined ? 'undefined' : e;
  setDiskonGlobalPersen(value || ' ');
  setDiskonGLobal("diskonglobalpersen")
  };

  const HargaDiskonNominal = (e) => {
    const value = e === undefined ? 'undefined' : e;
    setDiskonGlobalNominal(value || ' ');
    setDiskonGLobal("diskonglobalnominal")
    };
  
    const HargaOngkir = (e) => {
      const value = e === undefined ? 'undefined' : e;
      setOngkir(value || ' ');
      };

      const HargaDp = (e) => {
        const value = e === undefined ? 'undefined' : e;
        setDp(value || ' ');
        };
 

  return (
    <>
    {alert}
    <Button hidden color="danger" outline ref={cekPromo} onClick={()=>{CekItemCabang()}}>Klik Cek promo </Button>
    {salesOrderPusat && salesOrderPusat === "1" ? (
			<div>
    <SimpleHeader name="Buat Penjualan Barang" parentName="SO" />
    <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <CardBody>
                <Card className="position-sticky bg-secondary BoxShadow" style={{ top: 0, zIndex: "5" }}>
									<CardBody className="ml-3 pb-0">
										<Row md="12">
											<Col md="4">
											</Col>
											<Col md="4">
												<FormGroup row>
													<Col sm={10}>
														<Input
															className="form-control-alternative"
															placeholder="Search Kode Rfq"
															type="search"
															value={query}
															onChange={(e) => setQuery(e.target.value)}
														/>
													</Col>
												</FormGroup>
											</Col>
                      <Col md="4">
                      </Col>
										</Row>
									</CardBody>
								</Card>
									{isSearchShow && query && (
										<Card className="boxShadow" style={{ top: 100, zIndex: "3", maxHeight: "15.5rem", overflowY: "auto", paddingTop: "1rem", position: "sticky" }}>
											<div style={{ position: "absolute", top: "2.5px", right: "1rem", cursor: "pointer", fontSize: "2rem" }}>
												<i className="fas fa-window-close text-danger" onClick={() => setIsSearchShow(false)}></i>
											</div>
											<span className="text-center mb-3">
												<b>Pencarian berdasarkan:</b> {query}
											</span>
											{allItem?.response ? (
												allItem.response.map((item) => (
													<CardBody key={item.id} className="bgSearch border-bottom p-2 mb-2" style={{minHeight: 'auto'}} onClick={() => {searchShow(item); setQuery(''); setIsSearchShow(false);}}>
													  <div className=" d-flex align-items-center">
															<div className="ml-3">
																<div>
																	<b>Kode :</b> {item.rfq_code}
																</div>
															</div>
														</div>
													</CardBody>
												))
											) : (
												<div className="text-center mb-3 text-danger">Item "{query}" tidak ada bosku!</div>
											)}
										</Card>
                  )}
              
              <Card className="bg-secondary shadow">
                        <CardBody>
                        <Row md="12">
                          <Col md="6">
                          {coderfq === "" ? (
                              ""
                            ):(
                            <FormGroup row>
                              <Label
                              className="form-control-label" for="exampleEmail" sm={3}>
                                  Kode Rfq
                                <span className="text-danger">*</span>

                              </Label>
                              <Col sm={6}>
                                  <Input
                                  disabled
                                  autoComplete="off"
                                  className="form-control-alternative"
                                  name="person"
                                  type="text"
                                  value={coderfq}
                                  />
                              </Col>
                            </FormGroup>
                            )}
                          <FormGroup row>
                            <Label
                            className="form-control-label"
                                for="exampleEmail"
                                sm={3}
                            >
                                Cabang
                                <span className="text-danger">*</span>

                                
                            </Label>
                            <Col sm={6}>
                                <Input
                                    disabled={coderfq !== ""}
                                    autoComplete="off"
                                    className="form-control-alternative"
                                    type="select"
                                    value={warehousepusat}
                                    onChange={(e) => {
                                    setWarehousePusat(e.target.value);
                                    EditData()
                                    
                                    }}
                                >
                                    <option value={""}  selected disabled hidden>Pilih Cabang</option>
                                    {
                                    warehousepusats.map((a, key) => {
                                        return <option key={key} value={a.id} onClick={() => {EditData()}}>{a.name}</option>
                                    })
                                    }
                                    
                                </Input>
                            </Col>
                        </FormGroup>
                            <FormGroup row>
                              <Label
                              className="form-control-label"
                                for="exampleEmail"
                                sm={3}
                              >
                                Customer
                                <span className="text-danger">*</span>

                              </Label>
                              <Col sm={6}>
                                <Select2
                                      className="form-control-alternative"
                                      defaultValue="0"
                                      value={customer}
                                      onChange={(e) => {
                                        setCustomer(e.target.value);
                                        getAlamat(e.target.value);
                                        getbankCustomer(e.target.value);
                                      }}
                                      options={{
                                        placeholder: "Pilih Customer"
                                      }}
                                      data={customers.map((customer) => {
                                        return (
                                        { id: customer.id, text: customer.name}
                                        );
                                      })}
                                    />
                              </Col>
                            </FormGroup>
                            <FormGroup row>
														<Label
                              className="form-control-label" for="exampleEmail" sm={3}>
															Bank
                              <span className="text-danger">*</span>
														</Label>
														<Col sm={6}>
															<Input
                                className="form-control-alternative"
																type="select"
																value={bankcustomer}
																onChange={(e) => {
																	setBankCustomer(e.target.value);
																}}>
                                <option value=""  selected>Pilih Bank</option>
                                  {bankcustomers.map((supplier, key) => {
                                    return (
                                        <option key={key} value={supplier.account_number}>
                                          {supplier.bank_name} {supplier.account_number} {supplier.account_name} 
                                        </option>
                                    );
                                  })}
                                </Input>
														</Col>
													  </FormGroup>
                            <FormGroup row>
                              <Label
                              className="form-control-label"
                                for="exampleEmail"
                                sm={3}
                              >
                                Alamat
                                <span className="text-danger">*</span>

                              </Label>
                              <Col sm={6}>
                                <Input
                                className="form-control-alternative"
                                  type="textarea"
                                  name="keterangan"
                                  rows = "4"
                                  placeholder="Masukan Alamat"
                                  value={alamat}
                                  onChange={(e) => {
                                    setAlamat(e.target.value);
                                  }}
                                />
                              </Col>
                            </FormGroup>
                          </Col>
                          <Col md="6">
                          <FormGroup row>
                            <Label
                            className="form-control-label"
                              for="exampleEmail"
                              sm={4}
                            >
                              Tipe SO
                              <span className="text-danger">*</span>

                            </Label>
                            <Col sm={6}>
                            <Input
                            className="form-control-alternative"
                                name="Tipe Request"
                                type="select"
                                value={typereq}
                                onChange={(e) => {
                                  setTypeReq(e.target.value);
                                }}
                              >
                                <option value="" disabled hidden selected>Pilih Tipe SO</option>
                                <option value={1}>Ready</option>
                                <option value={2}>Indent</option>
                              </Input>
                            </Col>
                            </FormGroup>
                            <FormGroup row>
                            <Label
                            className="form-control-label"
                              for="exampleEmail"
                              sm={4}
                            >
                              Metode Pembayaran
                              <span className="text-danger">*</span>

                            </Label>
                            <Col sm={6}>
                            <Input
                            className="form-control-alternative"
                                name="Tipe Po"
                                type="select"
                                value={payment_method}
                                onChange={(e) => {
                                    setPaymentMethod(e.target.value);
                                }}
                              >
                                {/* <option value="" disabled hidden selected>Pilih Metode Pembayaran</option> */}
                                <option value={1}>Lunas</option>
                                <option value={2}>Tempo</option>
                                <option value={5}>Dp/Kredit</option>
                              </Input>
                            </Col>
                            </FormGroup>
                            {payment_method >  1  ? (
                              <FormGroup row>
                                <Label
                                className="form-control-label"
                                  for="exampleEmail"
                                  sm={4}
                                >
                                  Jangka Waktu
                                  <span className="text-danger">*</span>

                                </Label>
                                <Col sm={4}>
                                  <Input
                                    className="form-control-alternative"
                                    type="select"
                                    name="desc"
                                    value={jangkaWaktu}
                                    onChange={(e) => {
                                      setJangkaWaktu(e.target.value);
                                    }}
                                  >
                                  <option value="">Pilih jangka Waktu</option>
                                  {allJangkaWaktu.map((waktu, key) => {
                                    return (
                                      <option key={key} value={waktu.durasi}>
                                        {waktu.durasi}
                                      </option>
                                    );
                                  })}
                                </Input>
                                </Col> 
                                <Label
                                className="form-control-label" for="exampleEmail" sm={3}>
                                  <b>Hari</b>
                                </Label>
                              </FormGroup>
                            ):(
                              ""
                            )}
                            {payment_method === "5" ? 
                            <FormGroup row>
                              <Label
                              className="form-control-label"
                                for="exampleEmail"
                                sm={4}
                              >
                                Uang Muka
                              </Label>
                              <Col sm={6}>
                              <CurrencyInput
                                  id="validation-example-2-field"
                                  placeholder="Rp0"
                                  allowDecimals={false}
                                  value={dp}
                                  onValueChange={HargaDp}
                                  className={`form-control`}
                                  prefix={'Rp'}
                                  step={10}
                                />
                              </Col>                             
                            </FormGroup>
                            : 
                            ""
                            }
                            <FormGroup row>
                            <Label
                            className="form-control-label"
                              for="exampleEmail"
                              sm={4}
                            >
                              Pengiriman
                              <span className="text-danger">*</span>

                            </Label>
                            <Col sm={6}>
                            <Input
                            className="form-control-alternative"
                                name="Tipe Request"
                                type="select"
                                value={pengiriman}
                                onChange={(e) => {
                                  setPengiriman(e.target.value);
                                }}
                              >
                                {/* <option value="" disabled hidden selected>Pilih Pengiriman</option> */}
                                <option value={1}>Ambil Sendiri</option>
                                <option value={2}>Delivery</option>
                              </Input>
                            </Col>
                            </FormGroup>
                            {pengiriman >  1  ? (
                            <FormGroup row>
                              <Label
                              className="form-control-label"
                                for="exampleEmail"
                                sm={4}
                              >
                                Ongkir
                                <span className="text-danger">*</span>

                              </Label>
                              <Col sm={6}>
                              <CurrencyInput
                              disabled
                                  id="validation-example-2-field"
                                  placeholder="Rp0"
                                  allowDecimals={false}
                                  value={ongkir}
                                  onValueChange={HargaOngkir}
                                  className={`form-control`}
                                  prefix={'Rp'}
                                  step={10}
                                />
                              </Col>                             
                            </FormGroup>
                            ):(
                              ""
                            )}
                              <FormGroup row>
                              <Label
                              className="form-control-label" for="exampleEmail" sm={4}>
                                Diskon
                              </Label>
                              <Col sm={3}>
                                <CurrencyInput
                                  disabled={qtyTotal < 1000}
                                  id="validation-example-2-field"
                                  placeholder="0"
                                  allowDecimals={false}
                                  value={diskonglobalpersen}
                                  onValueChange={HargaDiskonPersen}
                                  className={`form-control`}
                                  suffix={'%'}
                                  step={10}
                                />
                              </Col>
                              <Col sm={3}>
                              <CurrencyInput
                                  disabled={qtyTotal < 1000}
                                  id="validation-example-2-field"
                                  placeholder="Rp0"
                                  allowDecimals={false}
                                  value={diskonglobalnominal}
                                  onValueChange={HargaDiskonNominal}
                                  className={`form-control`}
                                  prefix={'Rp'}
                                  step={10}
                                />
                              </Col>
                            </FormGroup>
                            <FormGroup row>
                              <Label
                              className="form-control-label" for="exampleEmail" sm={4}>
                                PPN
                                <span className="text-danger">*</span>

                              </Label>
                              <Col sm={6}>
                                <Input
                                  className="form-control-alternative"
                                    type="select"
                                    value={pajak}
                                    onChange={(e) => {
                                      setPajak(e.target.value);
                                      getByPajak(e.target.value);
                                    }}>
                                      {allpajak.map((warehouse2, key) => {
                                        return (
                                            <option key={key} value={warehouse2.id}>
                                              {warehouse2.keterangan}
                                            </option>
                                        );
                                      })}
                                </Input>
                              </Col>
                            </FormGroup>
                          </Col>
                      </Row>
                    </CardBody>
              </Card>
              <Form onSubmit={handleQtySisa} >
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                <h3>Item</h3>
                  </div>
                     
                    </CardHeader>
                        <CardBody>
                          <Table size="sm" responsive>
                            <thead>
                              <tr>
                                <th><b>Nama Item</b></th>
                                <th><b>Kode Item</b></th>
                                <th><b>Harga&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b></th>
                                <th><b>Qty&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b></th>
                                <th><b>Satuan</b></th>
                                <th><b>Diskon %</b></th>
                                <th><b>Diskon(N)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b></th>
                                <th><b>Sub Total</b></th>
                                <th>
                                  {/* {editable?(<Button color="danger" onClick={() => setEditable(false)} size="sm">Simpan</Button>
                                  ):(<Button onClick={() => setIsConfEditableOpen(true)} size="sm">Izinkan Edit</Button>)} */}
                                </th>
                              </tr>
                            </thead>
                              <tbody>
                              {
                                savedItems.map((savedItem, key) => {
                                  return (
                                    <tr key={key}>
                                      <td>{savedItem.item_name}</td>
                                      <td>{savedItem.item_code}</td>
                                      <td>
                                      
                                      {savedItem.edit_item ? (
                                          <Input
                                          className="form-control-alternative"
                                            placeholder="Diskon Persen"
                                            type="number"
                                            value={savedItem.harga}
                                            onChange={(e) => {
                                              handleEditHarga(key, e.target.value);
                                            }}
                                          />
                                        ) : (
                                            <>{formatRupiah(savedItem.harga)}</>
                                        )}
                                      
                                      </td>
                                      <td>
                                          <Input
                                          className="form-control-alternative"
                                          placeholder="qty"
                                          type="number"
                                          invalid={qtyError === "invalid"}
                                          value={savedItem.qty}
                                          onChange={(e) => {
                                            handleEditQty(key, e.target.value);
                                            setDiskonGLobal("diskonglobalpersen")
                                            if (savedItem.qty > savedItem.qtysisa) {
                                              setQtyError("");
                                            }
                                          }}/>
                                           <FormFeedback>
                                                Max Barang {savedItem.qtysisa} 
                                            </FormFeedback>
                                      </td>
                                      <td>{savedItem.satuan}</td>
                                      <td>
                                        {savedItem.edit_item ? (
                                          <Input
                                          className="form-control-alternative"
                                            placeholder="Diskon Persen"
                                            type="number"
                                            value={savedItem.diskon_persen}
                                            onChange={(e) => {
                                              handleEditDiskonPersen(key, e.target.value);
                                            }}
                                          />
                                        ) : (
                                            <>{savedItem.diskon_persen}</>
                                        )}
                                      </td>
                                      <td>
                                        {savedItem.edit_item ? (
                                          <Input
                                          className="form-control-alternative"
                                            placeholder="Diskon nominal"
                                            type="number"
                                            value={savedItem.diskon_nominal}
                                            onChange={(e) => {
                                              handleEditDiskonNominal(key, e.target.value);
                                            }}
                                          />
                                        ) : (
                                            <>{formatRupiah(savedItem.diskon_nominal)}</>
                                        )}
                                      </td>
                                      <td>
                                        {formatRupiah(((savedItem.harga - savedItem.diskon_nominal) * savedItem.qty) / (ppn/100 + 100/100))}
                                        {/* {formatRupiah((((savedItem.harga - savedItem.diskon_nominal) * savedItem.qty) - ((savedItem.harga - savedItem.diskon_nominal) * savedItem.qty) * (ppn/100)))} */}
                                        {/* {formatRupiah((savedItem.harga - savedItem.diskon_nominal) * savedItem.qty)} */}
                                      </td>
                                      <td>
                                      {savedItem.edit_item?(<Button color="primary" onClick={() => {setEditable(false);simpanEdit();}}><i className="fa fa-save" /></Button>
                                        ):(<Button color="warning" onClick={() => {setIsConfEditableOpen(true);setItemIDActive(savedItem.item_id);}}><i className="fas fa-user-edit" /></Button>)}
                                        <Button color="danger" onClick={() => deleteItem(savedItem.item_id)}><i className="fas fa-trash" /></Button>
                                      </td>
                                    </tr>
                                  )
                                })
                              }
                              <tr>
                                <td colSpan={2}>
                                  <><Form onSubmit={handleSubmit}>
                                          <Input
                                            autoComplete="off"
                                            placeholder="Masukan Item Manual"
                                            type="search"
                                            value={queryy}
                                            onChange={(e) => setQueryy(e.target.value)}
                                          />
                                        
                                        {isSearchShoww && queryy && (
                                        <Card className="position-sticky boxShadow" style={{ maxHeight: "15.5rem", overflowY: "auto", paddingTop: "1rem", position: "relative" }}>
                                          <div style={{ position: "absolute", top: "2.5px", right: "1rem", cursor: "pointer", fontSize: "1rem" }}>
                                            <i className="fas fa-window-close text-danger" onClick={() => setIsSearchShoww(false)}></i>
                                          </div>
                                          {allItemm?.response ? (
                                            allItemm.response.map((item) => (
                                              <CardBody key={item.id} style={{ minHeight: "5rem", padding: "1rem" }} className="bgSearch" onClick={() => {saveItemOrUpdate(item);setQueryy('');setIsSearchShoww(false);}}>
                                                <div>
                                                  <b>Nama item:</b> {item.item_name}
                                                </div>
                                                <div>
                                                  <b>Kode Item:</b> {item.item_code}
                                                </div>
                                              </CardBody>
                                            ))
                                          ) : (
                                            <div className="text-center mb-3 text-danger">Item "{queryy}" tidak ada bosku!</div>
                                          )}
                                        </Card>
                                      )}
                                  </Form></>
                                  </td>
                                 </tr>
                              </tbody>
                          </Table>
                        </CardBody>
                        <CardFooter>
                          <Row md="12">
                            <Col md="6">
                            <FormGroup row>
                              <Col sm={7}>
                                <Input
                                  type="textarea"
                                  name="keterangan"
                                  rows="10"
                                  placeholder="Masukan Keterangan"
                                  value={keterangan}
                                  onChange={(e) => {
                                    setKeterangan(e.target.value);
                                  }}
                                />
                              </Col>
                            </FormGroup>
                            </Col>
                            <Col md="2">
                            </Col>
                            <Col md="4">
                              <FormGroup row>
                              <Label
                              className="form-control-label"
                                for="exampleEmail"
                                sm={4}
                                size="small"
                              >
                                <b>Total</b>
                              </Label>
                              <Col sm={6}>
                              <Input
                              className="form-control-alternative"
                                disabled
                                style={{fontWeight: 'bold'}}
                                type="text"
                                name="barcode"
                                placeholder="Harga Total"
                                value={"Rp." + totalPrice1.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,") + ",-"}
                                />
                              </Col>
                              </FormGroup>
                              <FormGroup row>
                              <Label
                              className="form-control-label"
                                for="exampleEmail"
                                sm={4}
                              >
                                <b>Diskon</b>
                              </Label>
                              <Col sm={6}>
                              <Input
                                className="form-control-alternative"
                                style={{fontWeight: 'bold'}}
                                disabled
                                type="text"
                                name="barcode"
                                placeholder="Diskon"
                                value={"Rp." + totaldiskon2.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,") + ",-"}
                                />
                              </Col>
                              </FormGroup>
                              <FormGroup row>
                              <Label
                              className="form-control-label"
                                for="exampleEmail"
                                sm={4}
                              >
                                <b>PPN</b>
                              </Label>
                              <Col sm={6}>
                              <Input
                                className="form-control-alternative"
                                disabled
                                style={{fontWeight: 'bold'}}
                                type="text"
                                name="barcode"
                                placeholder="PPN"
                                value={"Rp." + ppnnew.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,") + ",-"}
                                />
                              </Col>
                              </FormGroup>
                              <FormGroup row>
                              <Label
                              className="form-control-label"
                                for="exampleEmail"
                                sm={4}
                              >
                                <b>Ongkir</b>
                              </Label>
                              <Col sm={6}>
                              <Input
                                className="form-control-alternative"
                                disabled
                                style={{fontWeight: 'bold'}}
                                type="text"
                                name="barcode"
                                placeholder="PPN"
                                value={"Rp." + ongkir1.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,") + ",-"}
                                />
                              </Col>
                              </FormGroup>
                              <FormGroup row>
                              <Label
                              className="form-control-label"
                                for="exampleEmail"
                                sm={4}
                              >
                                <b>Uang Muka</b>
                              </Label>
                              <Col sm={6}>
                              <Input
                                className="form-control-alternative"
                                disabled
                                style={{fontWeight: 'bold'}}
                                type="text"
                                name="barcode"
                                placeholder="Uang Muka"
                                value={"Rp." + dp1.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,") + ",-"}
                                />
                              </Col>
                              </FormGroup>
                              <FormGroup row>
                              <Label
                              className="form-control-label"
                                for="exampleEmail"
                                sm={4}
                              >
                                <b>Grand Total</b>
                              </Label>
                              <Col sm={6}>
                              <Input
                              className="form-control-alternative"
                                disabled
                                type="text"
                                name="barcode"
                                style={{fontWeight: 'bold'}}
                                placeholder="Grand Total"
                                value={"Rp." + grandtotal.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,") + ",-"}
                                />
                              </Col>
                              </FormGroup>

                            </Col>
                          </Row>
                        </CardFooter>
                <CardFooter>
                      <Link className="btn btn-info" to="/admin/pusat/sales-order/page">
                        Kembali
                      </Link>
                      <Button color="danger" type="submit">
                          Simpan
                      </Button>
                </CardFooter>
              </Card>
              </Form>
            </CardBody>
          </div>
        </Row>
        {/* modal konfirmasi editable */}
			<Modal isOpen={isConfEditableOpen} fade={false} style={{ minWidth: "30%", top: "10%" }}>
				<ModalHeader toggle={() => setIsConfEditableOpen(!isConfEditableOpen)}>Masukan Username Dan Password</ModalHeader>
				<ModalBody
					cssModule={{
						alignText: "center",
					}}>
						<Form onSubmit={handleSubmit4}>
						<Row className="justify-content-center mt-4">
								<Col xs={7}>
									<Input
										autoComplete="off"
										placeholder="Masukan Username"
										type="text"
										value={nameotorisasi}
										invalid={nameotorisasiError === "invalid"}
										onChange={(e) => {
											setNameOtorisasi(e.target.value);
											if (e.target.value !== "") {
											setNameOtorisasiError("");
											}
										}}
									/>
								</Col>
							</Row>
							<Row className="justify-content-center mt-4">
								<Col xs={7}>
									<Input
										autoComplete="off"
										placeholder="Masukan Password Otorisasi"
										type="password"
										value={confPassEditable}
										onChange={(e) => setConfPassEditable(e.target.value)}
									/>
								</Col>
							</Row>
							<Row className="justify-content-center mt-4">
								<Col xs={7}>
										<Input
                      //   className="form-control-alternative"
                        autoComplete="off"
                        type="select"
											  value={keterangan5}
											  invalid={keteranganError === "invalid"}
											  onChange={(e) => {
												  setKeterangan5(e.target.value);
												  if (e.target.value !== "") {
												  setKeteranganError("");
												  }
											  }}>
                          <option value="" disabled hidden selected>Pilih Alasan</option>
                            {reasons.map((reason, key) => {
                                return (
                                <option key={key} value={reason.description}>
                                    {reason.description}
                                </option>
                                );
                            })}
                      </Input>
									<br/>
									<Button color="primary" type="submit">CEK <i className="fa fa-search" /></Button>
								</Col>
							</Row>
						</Form>
				</ModalBody>
			</Modal>
    </Container>
    </div>
    ):(
      <Halaman404 />
    )}
    </>
  );
}

export default CreateSalesOrderPusat;