/*eslint-disable*/
import React, { useEffect, useState } from "react";
import {Row,CardBody,CardHeader,Form,Input,Col,Spinner,FormGroup,Nav,NavItem,Label ,Button ,TabPane,DropdownItem,Badge,Card,DropdownMenu,UncontrolledDropdown,Container,DropdownToggle,Modal, ModalHeader, ModalBody} from "reactstrap";
import axios from "axios";
// import { Link } from "react-router-dom";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import Detail from "views/pages/itemStock/StockPribadi/Detail"
import SimpleHeader from "components/Headers/SimpleHeader.js";
import SweetAlert from "react-bootstrap-sweetalert";


const StockPribadi = () => {
const [alert, setAlert] = React.useState(null);
const token = localStorage.token;
  const warehouse = localStorage.warehouse;
  const [loading, setLoading] = useState(false);
  const [hide, setHide] = useState(true);
  const [nameStock, setNameStock] = useState("");
  const [codeStock, setCodeStock] = useState("");
  const [barcode, setBarcode] = useState("");
  const [allItemStock, setAllItemStock] = useState([]);
  const [page, setPage] = useState(1);
  const [perPage, setPerpage] = useState(10);
  const [totalItem, setTotalItem] = useState(0);
  const [currentSort, setCurrentSort] = useState("");
  const [rowIndex, setRowIndex] = useState(0);
  const [detailItemStok, setDetailItemStok] = useState(0);
  const toggle1 = () => setModal1(!modal1);
  const [modal1, setModal1] = useState(false);
  const toggle2 = () => setModal2(!modal2);
  const [modal2, setModal2] = useState(false);
  const toggle3 = () => setModal3(!modal3);
  const [modal3, setModal3] = useState(false);
  const toggle4 = () => setModal4(!modal4);
	const [datalog, setDataLog] = useState([])
	const [modal4, setModal4] = useState(false);
  const [namaitem, setNamaItem] = useState([])
  const [namaitems, setNamaItems] = useState([]);
  // const [status, setStatus] = useState("");
  const [active, setActive] = useState("");
  const headers = { Authorization: `Bearer ${token}` };
  const [rowIndexLokasi, setRowIndexLokasi] = useState(1);
  const [rowIndexRak, setRowIndexRak] = useState(1);
  const [rowIndexStok, setRowIndexStok] = useState(1);
  const [namaproyekLokasi,setNamaProyekLokasi] = useState([]);
  const [namaraklokasi,setNamaRakLokasi] = useState([]);
  const [namaitemproyeklokasi,setNamaItemProyekLokasi] = useState("");
  // const allInfo = JSON.parse(localStorage.allinfo);
  // const CreateButton = String(allInfo.privileges.filter((i) => i.privilege_name === "Sub Stock").map((p) => p.create_access));

  const cekStok = async(id) => {
    axios.get(
            `${process.env.REACT_APP_API_BASE_URL}/log-stock/get-by-item?item_id=${id}&wid=${warehouse}`, {headers}
          )
          .then((data) => {
            setNamaItem(data.data.response);
            setNamaItems(data.data.response[0].item_name)
            setModal1(!modal1);
          })
          .catch(function (error) {
            setGagalAlert(error.response.data.message);
          });
  };

  const cekStokLokasi = async(id) => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios.get(
            `${process.env.REACT_APP_API_BASE_URL}/item-stok/get-cabang/${id}`, {headers,}
          )
          .then((data) => {
            setNamaProyekLokasi(data.data.response);
            setNamaItemProyekLokasi(data.data.response[0].item_name);
            setModal2(!modal2);
          })
          .catch(function (error) {
            setGagalAlert(error.response.data.message);
          });
  };

  const cekStokLokasiRak = async(id) => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios.get(
            `${process.env.REACT_APP_API_BASE_URL}/item-stok/get-detail/${id}/${warehouse}`, {headers,}
          )
          .then((data) => {
            setNamaRakLokasi(data.data.response);
            setModal3(!modal3);
          })
          .catch(function (error) {
            setGagalAlert(error.response.data.message);

          });
  };

  
  let paginationOption = {
    page: page,
    alwaysShowAllBtns: true,
    override: true,
    showTotal: true,
    withFirstAndLast: false,
    sizePerPage: perPage,
    totalSize: totalItem,
    onPageChange: (page) => {
      updateDataTable(page, perPage, currentSort, nameStock, codeStock, barcode,active);
    },
    sizePerPageRenderer: () => (
      <div className="dataTables_length" id="datatable-basic_length">
        <label>
          Show{" "}
          {
            <select
              name="datatable-basic_length"
              aria-controls="datatable-basic"
              className="form-control form-control-sm"
              onChange={(e) => {
                updateDataTable(page, e.target.value, currentSort)
              }}
            >
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="25">25</option>
              <option value="50">50</option>
            </select>
          }{" "}
          entries.
        </label>
      </div>
    ),
  }

  const updateDataTable = (page, perPage, sort, nameStock, codeStock, barcode,active) => {
    getItemOwnStock(page, perPage, sort, nameStock, codeStock, barcode,active);
    setPage(page);
    setPerpage(perPage);
    setRowIndex((page - 1) * perPage);
    setRowIndexLokasi((page - 1) * perPage);
    setRowIndexRak((page - 1) * perPage);
    setRowIndexStok((page - 1) * perPage);
    setCurrentSort(sort);
    setNameStock(nameStock);
    setCodeStock(codeStock);
    setBarcode(barcode);
    setActive(active)
  }

  const handleTableChange = (type, { sortField, sortOrder }) => {
    if (type === "sort") {
      let sort = `${sortField} ${sortOrder}`
      updateDataTable(page, perPage, sort,  nameStock, codeStock, barcode, active)
    }
  }

  // const toggle = tab => {
  //   if (activeTab !== tab) setActiveTab(tab);
  // }

  // let paginationOption = {
  //   page: page,
  //   alwaysShowAllBtns: true,
  //   override: true,
  //   showTotal: true,
  //   withFirstAndLast: false,
  //   sizePerPage: perPage,
  //   totalSize: totalItem,
  //   onPageChange: (page) => {
  //     updateDataTable(
  //       page,
  //       perPage,
  //       currentSort,
  //       nameStock,
  //       codeStock,
  //       barcode
  //     );
  //   },
  //   sizePerPageRenderer: () => (
  //     <div className="dataTables_length" id="datatable-basic_length">
  //       <label
  //       className="form-control-label"
  //       >
  //         Show{" "}
  //         {
  //           <select
  //             name="datatable-basic_length"
  //             aria-controls="datatable-basic"
  //             className="form-control form-control-sm"
  //             onChange={(e) => {
  //               updateDataTable(page, e.target.value, currentSort);
  //             }}
  //           >
  //             <option value="10">10</option>
  //             <option value="20">20</option>
  //             <option value="25">25</option>
  //             <option value="50">50</option>
  //           </select>
  //         }{" "}
  //         entries.
  //       </label>
  //     </div>
  //   ),
  // };

  // const updateDataTable = (page, perPage, sort, nameStock, codeStock, barcode,active) => {
  //   getItemOwnStock(page, perPage, sort, nameStock, codeStock, barcode,active);
  //   setPage(page);
  //   setPerpage(perPage);
  //   setRowIndex((page - 1) * perPage);
  //   setRowIndexLokasi((page - 1) * perPage);
  //   setRowIndexRak((page - 1) * perPage);
  //   setRowIndexStok((page - 1) * perPage);
  //   setCurrentSort(sort);
  //   setNameStock(nameStock);
  //   setCodeStock(codeStock);
  //   setBarcode(barcode);
  //   setActive(active)
  // };

  // const handleTableChange = (type, { sortField, sortOrder }) => {
  //   if (type === "sort") {
  //     let sort = `${sortField} ${sortOrder}`;
  //     updateDataTable(page, perPage, sort, nameStock, codeStock, barcode, active);
  //   }
  // };

  useEffect(() => {
    getItemOwnStock(page, perPage, currentSort, "", "");
  }, []);

  const getItemOwnStock = async (page, perPage, currentSort, item_name=null , item_code=null ) => {
    setLoading(true); // Set loading to true before making the request
    let filter = {
      page: page,
      per_page: perPage,
      warehouse_id: parseInt(warehouse),
      barcode: barcode,
      item_name : nameStock,
      item_code: codeStock,
      active_flag: active

    };
    // if (item_name !== null ) {
    //   filter = Object.assign(filter, { item_name: item_name })
    // }
    // if (item_code !== null ) {
    //     filter = Object.assign(filter, { item_code: item_code })
    // }
    // if (barcode !== null ) {
    //   filter = Object.assign(filter, { barcode: barcode })
    // }
    const data = filter;
    const headers = {
      Authorization: `Bearer ${token}`,
    };
     await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/item-stok/page`,
      data,
      { headers }
    )
    .then((data) => {
      setAllItemStock(data.data.response);
      setPage(data.data.current_page + 1);
      setPerpage(data.data.per_page);
      setTotalItem(data.data.total_item);
      setLoading(false); // Set loading to true before making the request
    })
    .catch(function (error) {
      setGagalAlert(error.response.data.message);
          // setGagalAlert(error.response.data.message);
    });
}

const getDataLog = (id) => {
  let filter = { 
    transaction : id,
    features : "TRANSFER1",
  }
  const data = filter;
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`
    }
  axios.post(`${process.env.REACT_APP_API_BASE_URL}/riwayat-data/info`, data, { headers
  })
  .then(data => {
    setDataLog(data.data.response);
    setModal4(!modal4);

  })
    .catch(function (error) {
    setGagalAlert(error.response.data.message);
    })
  }


const setGagalAlert = (id) => {
setAlert(
<SweetAlert
danger
showConfirm
confirmBtnText="Ok"
title={id}
onCancel={hideAlert}
onConfirm={hideAlert}
/>
);
};

const hideAlert = () => {
  setAlert(null);
};

  const reset = () => {
    setNameStock("");
    setCodeStock("");
    setBarcode("");
    setActive("")
    getItemOwnStock();
  }
  const rowEvents = {
    onDoubleClick: (e, row, rowIndex) => {
      setHide(false);
      setDetailItemStok(row.id);
    },
  };

  const formatRupiah = (money) => {
    return new Intl.NumberFormat('id-ID',
        { style: 'currency', currency: 'IDR', minimumFractionDigits: 0 }
    ).format(money);
  }

  const setQuestionAlertActiveFlag1 = (id) => {
    setAlert(
      <SweetAlert
        warning
        showCancel
        confirmBtnText="Lanjutkan"
        confirmBtnBsStyle="danger"
        title="Apakah Kamu Yakin?"
        onConfirm={()=>functionActiveflag1(id)}
        onCancel={hideAlert}
        focusCancelBtn
        />
      );
    };

    const setQuestionAlertActiveFlag2 = (id) => {
      setAlert(
        <SweetAlert
          warning
          showCancel
          confirmBtnText="Lanjutkan"
          confirmBtnBsStyle="danger"
          title="Apakah Kamu Yakin?"
          onConfirm={()=>functionActiveflag2(id)}
          onCancel={hideAlert}
          focusCancelBtn
          />
        );
      };

 

const functionActiveflag1 = async (id) => {
  

    let data = {
      id : id,
      warehouse_id: parseInt(warehouse),
      active_flag : 1,

      }
      await axios.post(`${process.env.REACT_APP_API_BASE_URL}/items-cabang/active-flag`, data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      }
      })
      .then(function (response) {
        getItemOwnStock()
        statuscok(response.data.message)
        setLoading(false)
    })
    .catch(function (error) {
      getItemOwnStock()
      statuscok(error.response.data.message)
    });
  }

  const functionActiveflag2 = async (id) => {
    let data = {
      id : id,
      warehouse_id: parseInt(warehouse),
      active_flag : 2,

      }
      await axios.post(`${process.env.REACT_APP_API_BASE_URL}/items-cabang/active-flag`, data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      }
      })
      .then(function (response) {
        getItemOwnStock()
        statuscok(response.data.message)
    })
    .catch(function (error) {
      getItemOwnStock()
      statuscok(error.response.data.message)
    });
  }

const statuscok = (id) => {
  setAlert(
  <SweetAlert
  success
  showConfirm
  confirmBtnText="Ok"
  title={id}
  timeout={2000}
  onCancel={hideAlert}
  onConfirm={hideAlert}
  />
  );
  };

  // console.log(status);

  return (
    <div>
      {alert}
      <SimpleHeader name="Stok" parentName="PO" />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <CardBody>
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                {/* <div
                  style={{ display: "flex", justifyContent: "space-between" }}>
                  <h3></h3>
                  {CreateButton && CreateButton === "1" && (
                  <div style={{ textAlign: 'right' }}>
                    <Link className="btn btn-danger" to="/admin/stock-item/create">
                    <i className="fas fa-plus" /> Tambah
                    </Link>
                  </div>
                  )}
                </div> */}
                </CardHeader>
              <CardBody>
                      <Form onSubmit={getItemOwnStock}>
                        <Row md="12">
                          <Col md="3">
                            <FormGroup>
                              <Label
                              className="form-control-label"
                              htmlFor="exampleFormControlSelect3">Nama Item</Label>
                              <Input
                                type="text"
                                placeholder="Masukan Nama Item"
                                value={nameStock}
                                onChange={(e) => {
                                  setNameStock(e.target.value);
                                }}
                                // onChange={e => updateDataTable(1, perPage, currentSort, e.target.value, codeStock, barcode)}
                              />
                            </FormGroup>
                          </Col>
                          <Col md="3">
                            <FormGroup>
                              <Label
                              className="form-control-label"
                              htmlFor="exampleFormControlSelect3">Kode Item</Label>
                              <Input
                                type="text"
                                placeholder="Masukan Kode Item"
                                value={codeStock}
                                onChange={(e) => {
                                  setCodeStock(e.target.value);
                                }}
                                // onChange={e => updateDataTable(1, perPage, currentSort, nameStock, e.target.value, barcode)}
                              />
                            </FormGroup>
                          </Col>
                          <Col md="3">
                            <FormGroup>
                              <Label
                              className="form-control-label"
                              htmlFor="exampleFormControlSelect3">Barcode</Label>
                              <Input
                                type="text"
                                placeholder="Masukan Barcode"
                                value={barcode}
                                onChange={(e) => {
                                  setBarcode(e.target.value);
                                }}
                                // onChange={e => updateDataTable(1, perPage, currentSort, nameStock, codeStock, e.target.value, )}
                              />
                            </FormGroup>
                          </Col>
                          <Col md="3">
                            <FormGroup>
                              <Label
                              className="form-control-label"
                              htmlFor="exampleFormControlSelect3">Status</Label>
                              <Input
                                type="select"
                                placeholder="Masukan Barcode"
                                value={active}
                                onChange={(e) => {
                                  setActive(e.target.value);
                                }}
                                // onChange={e => updateDataTable(1, perPage, currentSort, nameStock, codeStock, e.target.value, )}
                              >
                                <option value={""}>Pilih Status Item</option>
                                <option value={1}>Aktif</option>
                                <option value={2}>Nonaktif</option>
                                </Input>
                            </FormGroup>
                          </Col>
                          <Col>
                            <FormGroup>
                              <Label
                              className="form-control-label"
                              >&nbsp;</Label>
                              <br></br>
                              {/* <Button type="button" onClick={()=>{getItemOwnStock()}} color="info"> */}
                              <Button type="button" onClick={()=>{getItemOwnStock()}} color="info">
                              <i className="fa fa-filter"></i>
                              </Button>
                              <Button type="button" onClick={reset} color="secondary">
                                Reset
                              </Button>
                            </FormGroup>
                          </Col>
                        </Row>
                      </Form>
                      {/* <div className="table-sm my--4"> */}
                      {loading ? (
                          <div className="text-center">
                            <Spinner color="primary" />
                          </div>
                        ) : (
                        <ToolkitProvider
                          rowNumber={rowIndex}
                          data={allItemStock}
                          keyField="id"
                          columns={[
                            {
                              dataField: "no",
                              text: "#",
                              sort: true,
                              page: 1,
                              formatter: (cell, row, index) => {
                                let currentRow = ++index;
                                return currentRow + rowIndex;
                              },
                            },
                            {
                              dataField: "item_code",
                              text: "Kode Item",
                              sort: true,
                              formatter: (cell, row) => {
                                return row.item_code === ""
                                  ? <h2>
                                    <Badge color="warning" >
                                      {row.item_code}
                                    </Badge>
                                    </h2>
                                  : 
                                  <h2>
                                  <Badge color="warning" >
                                      {row.item_code}
                                    </Badge>
                                    </h2>
                              },
                          },
                            {
                              dataField: "barcode",
                              text: "Barcode",
                              sort: true,
                              formatter: (cell, row) => {
                                return row.barcode === ""
                                  ? <h2>
                                    <Badge color="danger" >
                                      {row.barcode}
                                    </Badge>
                                    </h2>
                                  : 
                                  <h2>
                                  <Badge color="danger" >
                                      {row.barcode}
                                    </Badge>
                                    </h2>
                              },
                          },
                            {
                              dataField: "item_name",
                              text: "Nama Item",
                              sort: true,
                            },
                            {
                              dataField: "qty_total",
                              text: "Stok",
                              sort: true,
                            },
                            {
                              dataField: "satuan",
                              text: "Satuan",
                              sort: true,
                            },
                            {
                              dataField: "active_flag",
                              text: "Status",
                              sort: true,
                              formatter: (cell, row) => {
                                return row.active_flag === 1
                                  ? 
                                    <Badge color="" className="badge-dot mr-4">
                                      <i className="bg-success" />
                                      <b>Aktif</b>
                                    </Badge>
                                  : <Badge color="" className="badge-dot mr-4">
                                      <i className="bg-warning" />
                                      <b>Tidak Aktif</b>
                                    </Badge>
                              },
                          },
                        {
                          dataField: "", text: "", formatter: (cell, row, index) => {
                          return row.active_flag === 1 ? 
                          <>
                            <UncontrolledDropdown group>
                              <DropdownToggle className="nav-link pr-0" color="" tag="a">
                                  <Button className="btn btn-danger">
                                    Cek 
                                  </Button>
                              </DropdownToggle>
                              <DropdownMenu>
                              <DropdownItem className={"id"+row.id} onClick={() => (getDataLog(row.id))}>
                                    <i className="fa fa-info-circle" aria-hidden="true"></i><span>Info</span>
                                  </DropdownItem>
                                  <DropdownItem className={"id"+row.id} onClick={() => {cekStok(row.id)}}>
                                      <i className="fas fa-book" /><span>Kartu Stok</span>
                                  </DropdownItem>
                                  <DropdownItem className={"id"+row.id} onClick={() => {cekStokLokasi(row.id)}}>
                                    <i className="fas fa-book" /><span>Cabang Stok</span>
                                  </DropdownItem>
                                  <DropdownItem className={"id"+row.id} onClick={() => {cekStokLokasiRak(row.id)}}>
                                    <i className="fas fa-book" /><span>Lokasi Rak</span>
                                  </DropdownItem>
                              </DropdownMenu>
                            </UncontrolledDropdown>
                            &nbsp;
                            <Button type="button" onClick={()=>{setQuestionAlertActiveFlag2(row.id)}} color="primary">
                                Nonaktif
                            </Button>
                          </>
                          :
                          <>
                            <UncontrolledDropdown group>
                                <DropdownToggle className="nav-link pr-0" color="" tag="a">
                                    {/* <Button className="btn btn-danger btn-sm"> */}
                                    <Button className="btn btn-danger">
                                      Cek 
                                    </Button>
                                </DropdownToggle>
                                <DropdownMenu>
                                  <DropdownItem className={"id"+row.id} onClick={() => (getDataLog(row.id))}>
                                      <i className="fa fa-info-circle" aria-hidden="true"></i><span>Info</span>
                                    </DropdownItem>
                                    <DropdownItem className={"id"+row.id} onClick={() => {cekStok(row.id)}}>
                                        <i className="fas fa-book" /><span>Kartu Stok</span>
                                    </DropdownItem>
                                    <DropdownItem className={"id"+row.id} onClick={() => {cekStokLokasi(row.id)}}>
                                      <i className="fas fa-book" /><span>Cabang Stok</span>
                                    </DropdownItem>
                                    <DropdownItem className={"id"+row.id} onClick={() => {cekStokLokasiRak(row.id)}}>
                                      <i className="fas fa-book" /><span>Lokasi Rak</span>
                                    </DropdownItem>
                                </DropdownMenu>
                            </UncontrolledDropdown>
                            &nbsp;
                            <Button type="button" onClick={()=>{setQuestionAlertActiveFlag1(row.id)}} color="warning">
                              Aktif
                            </Button>
                        </>
                          }
                      },
                          ]}
                        >
                          {(props) => (
                            <div className="py-4 table-responsive">
                              <BootstrapTable
                                remote
                                {...props.baseProps}
                                bootstrap4={true}
                                bordered={false}
                                hover={true}
                                rowEvents={rowEvents}
                                pagination={paginationFactory({ ...paginationOption })}
                                onTableChange={handleTableChange}
                              />
                            </div>
                          )}
                        </ToolkitProvider>
                          )}
                      {/* </div> */}
              </CardBody>
            </Card>
            </CardBody>
              <Nav tabs>
                <NavItem>
                </NavItem>
              </Nav>
              {hide ? (
                <></>
              ) : (
                <>
                    <TabPane tabId="1">
                      <Row>
                        <Col md="12">
                          <Detail code={detailItemStok}/>
                        </Col>
                      </Row>
                    </TabPane>
                </>
              )}
          </div>
        </Row>
      </Container>
      {/* Kartu Stok */}
      <Modal isOpen={modal1} toggle={toggle1} style={{ minWidth: "70%", top: "5%"  }}>
        <ModalHeader toggle={toggle1} className="text-center" align="center"></ModalHeader>
        <ModalBody align="center">
          Kartu Stok : <b>{namaitems}</b>
          <CardBody>
          <ToolkitProvider 
              rowNumber={rowIndexStok}
              data={namaitem}
              keyField="id"
              columns={[
                  {
                  dataField: "no",
                  text: "#",
                  sort: true,
                  page: 1,
                  formatter: (cell, row, index) => {
                      let currentRow = ++index;
                      return currentRow + rowIndexStok;
                  },
                  },
                  {
                  dataField: "transaction_date",
                  text: "Tanggal",
                  sort: true,
                  },
                  {
                  dataField: "code_transaction",
                  text: "Kode Transaksi",
                  sort: true,
                  },
                  {
                  dataField: "someone",
                  text: "Customer/Supplier",
                  sort: true,
                  },
                  {
                  dataField: "item_code",
                  text: "Kode Item",
                  sort: true,
                  },
                  {
                    dataField: "harga",
                    text: "Harga",
                    sort: true,
                    formatter: (value) => formatRupiah(value)
                  },
                  {
                  dataField: "qty_masuk",
                  text: "Masuk",
                  sort: true,
                  },
                  {
                  dataField: "qty_keluar",
                  text: "Keluar",
                  sort: true,
                  },
                  {
                  dataField: "qty_sisa",
                  text: "Sisa",
                  sort: true,
                  },
                  {
                  dataField: "diskon_nominal",
                  text: "Diskon Nominal",
                  sort: true,
                  formatter: (value) => formatRupiah(value)
                  },
                  {
                  dataField: "sub_total",
                  text: "Total",
                  sort: true,
                  formatter: (value) => formatRupiah(value)
                  },
                  {
                  dataField: "note",
                  text: "Note",
                  sort: true,
                  },
                  {
                  dataField: "status",
                  text: "Status",
                  sort: true,
                  },
              ]}
              >
              {(props) => (
                  <div className="py-4 table-responsive">
                  <BootstrapTable
                      remote
                      {...props.baseProps}
                      bootstrap4={true}
                      bordered={false}
                      hover={true}
                  />
                  </div>
              )}
              </ToolkitProvider>
          </CardBody>
          <Button color="secondary" onClick={toggle1}>
            Kembali
          </Button>
        </ModalBody>
      </Modal>  
      <Modal isOpen={modal2} toggle={toggle2} style={{ minWidth: "70%", top: "5%"  }}>
        <ModalHeader toggle={toggle2} className="text-center" align="center"></ModalHeader>
        <ModalBody align="center">
          Item Cabang : <b>{namaitemproyeklokasi}</b>
          <Container>
          <CardBody>
            
        <ToolkitProvider
              rowNumber={rowIndexLokasi}
              data={namaproyekLokasi}
              keyField="id"
              columns={[
                  {
                  dataField: "no",
                  text: "#",
                  sort: true,
                  page: 1,
                  formatter: (cell, row, index) => {
                      let currentRow = ++index;
                      return currentRow + rowIndexLokasi;
                  },
                  },
                  {
                  dataField: "warehouse",
                  text: "Cabang",
                  sort: true,
                  },
                  {
                  dataField: "item_name",
                  text: "Nama Item",
                  sort: true,
                  },
                  {
                  dataField: "item_code",
                  text: "Kode Item",
                  sort: true,
                  },
                  {
                  dataField: "barcode",
                  text: "Barcode",
                  sort: true,
                  },
                  {
                  dataField: "qty_total",
                  text: "Stok",
                  sort: true,
                  },
                  {
                  dataField: "satuan",
                  text: "Satuan",
                  sort: true,
                  },
              ]}
              >
              {(props) => (
                  <div className="py-4 table-responsive">
                  <BootstrapTable
                      remote
                      {...props.baseProps}
                      bootstrap4={true}
                      bordered={false}
                      hover={true}
                  />
                  </div>
              )}
              </ToolkitProvider>
          <Button color="secondary" onClick={toggle2}>
          Kembali
          </Button>
          </CardBody>
          </Container>
        </ModalBody>
      </Modal> 
      <Modal isOpen={modal3} toggle={toggle3} style={{ minWidth: "70%", top: "5%"  }}>
        <ModalHeader toggle={toggle3} className="text-center" align="center"></ModalHeader>
        <ModalBody align="center">
        <b>Lokasi Rak</b>
          <Container>
          <CardBody>
        <ToolkitProvider
              rowNumber={rowIndexRak}
              data={namaraklokasi}
              keyField="id"
              columns={[
                  {
                  dataField: "no",
                  text: "#",
                  sort: true,
                  page: 1,
                  formatter: (cell, row, index) => {
                      let currentRow = ++index;
                      return currentRow + rowIndexRak;
                  },
                  },
                  {
                  dataField: "code_rak",
                  text: "Kode Rak",
                  sort: true,
                  },
                  {
                    dataField: "qty_masuk",
                    text: "Masuk",
                    sort: true,
                  },
                  {
                  dataField: "qty_keluar",
                  text: "Keluar",
                  sort: true,
                  },
                  {
                  dataField: "qty_sisa",
                  text: "Sisa",
                  sort: true,
                  }
                  
              ]}
              >
              {(props) => (
                  <div className="py-4 table-responsive">
                  <BootstrapTable
                      remote
                      {...props.baseProps}
                      bootstrap4={true}
                      bordered={false}
                      hover={true}
                  />
                  </div>
              )}
              </ToolkitProvider>
          <Button color="secondary" onClick={toggle3}>
          Kembali
          </Button>
          </CardBody>
          </Container>
        </ModalBody>
      </Modal> 
      <Modal isOpen={modal4} toggle={toggle4} style={{ minWidth: "70%", top: "5%"  }}>
        <ModalHeader toggle={toggle4} className="text-center" align="center"></ModalHeader>
        <ModalBody align="center">
          <h2><b>Log Info</b></h2> 
          <CardBody>
          <ToolkitProvider 
              rowNumber={rowIndex}
              data={datalog}
              keyField="id"
              columns={[
                  {
                  dataField: "no",
                  text: "#",
                  sort: true,
                  page: 1,
                  formatter: (cell, row, index) => {
                      let currentRow = ++index;
                      return currentRow + rowIndex;
                  },
                  },
				  {
					dataField: "api_hit",
					text: "Tanggal Update",
					sort: true,
					},
                  {
                  dataField: "username",
                  text: "PIC",
                  sort: true,
                  },
                  {
                  dataField: "lost_data",
                  text: "Data Lama",
                  sort: true,
                  },
                  {
                  dataField: "show_data",
                  text: "Data Baru",
                  sort: true,
                  },
              ]}
              >
              {(props) => (
                  <div className="py-4 table-responsive">
                  <BootstrapTable
                      remote
                      {...props.baseProps}
                      bootstrap4={true}
                      bordered={false}
                      hover={true}
                  />
                  </div>
              )}
              </ToolkitProvider>
          </CardBody>
          <Button color="secondary" onClick={toggle4}>
            Kembali
          </Button>
        </ModalBody>
      </Modal> 
    </div>
  );
};

export default StockPribadi;