/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { Card, Button, Row, Col, CardBody, CardHeader, CardFooter,Table,Container, FormGroup,Label,Input,} from "reactstrap";
import { Link,useHistory } from "react-router-dom";
import axios from "axios";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import moment from "moment";
import SweetAlert from "react-bootstrap-sweetalert";
import Halaman404 from "views/404";
import CurrencyInput from 'react-currency-input-field';

export default function EditValidatorPoPusat(props)  {
  const token = localStorage.token;
  const username = localStorage.username;
  const [alert, setAlert] = React.useState(null);
  let history = useHistory();
  const [personname, setPersonName] = useState("");
  const [durasis, setDurasis] = useState([]);
  const [durasi, setDurasi] = useState("");
  const [paymentmetode,setPaymentMethod] = useState([]);
  const [keterangan, setKeterangan] = useState("");
  const [allJangkaWaktu, setAllJangkaWaktu] = useState([]);
  const [jangkaWaktu,setJangkaWaktu] = useState(0);
  const [savedItems, setSavedItems] = useState([]);
  const [coderfq, setCodeRfq] = useState([]);
  const [sales, setSales] = useState("");
  const [adminpo, setAdminPo] = useState("");
  const [approve, setApprove] = useState([]);
  const [statusap,setStatusAp] = useState([]);
  const [statusd,setStatusD] = useState(3);
  const [ongkir, setOngkir] = useState(0);
  const [lainnya, setLainnya] = useState(0);
  const [allpajak, setAllPajak] = useState([]);
  const [pajak, setPajak] = useState("");
  const [ppn, setPPN] = useState(0);
  const [totaldiskon, setTotalDiskon] = useState(0);
  const [grandtotal, setGrandTotal] = useState(0);
  const [diskonglobalnominal, setDiskonGlobalNominal] = useState(0);
  const [diskonglobalpersen, setDiskonGlobalPersen] = useState(0);
  const [ongkir1, setOngkir1] = useState(0);
	const [ppnnew, setPPNNEW] = useState(0);
	const [diskonglobal, setDiskonGLobal] = useState(0);
  const [totalPrice1, setTotalPrice1] = useState(0);
  const [diskonglobalnominal1, setDiskonGlobalNominal1] = useState(0)
  const a = 0;
	const b = 0;
	const c = 0;
  const [dp, setDp] = useState(0);
  const [dp1, setDp1] = useState(0);
	const [totaldiskon2, setTotalDiskon2] = useState(0);
	const [totaldiskon3, setTotalDiskon3] = useState(0);
	const [qtyTotal, setTotalQty] = useState(0);
	const [diskontotal, setDiskonTotal] = useState(0);
  const [cabang, setCabang] = useState("");
  const [cabangs, setCabangs] = useState([]);
  const [tanggal, setTanggal] = useState("");
  const [codepo,setCodePo] = useState("");
  const [banksupplier, setBankSupplier] = useState("")
  const [banksuppliers, setBankSuppliers] = useState([])
  const allInfo = JSON.parse(localStorage.allinfo);
  const penawaranPOs = String(allInfo.privileges.filter((i) => i.privilege_name === "Sub PO Pusat").map((p) => p.read_access));
  
  useEffect(() => {
    getCabang();
  }, []);

  const getCabang = (id) => {
      const headers = {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      axios.get(`${process.env.REACT_APP_API_BASE_URL}/warehouse/list/all`, { headers
      })
      .then(data => {
        setCabangs(data.data.response);
        setCabang(id);
      })
        .catch(function (error) {
          console.log(error)
        })
    }

    useEffect(() => {
      setDiskonGlobalNominal1(diskonglobalnominal);
    }, [diskonglobalnominal]);
  
      // diskon bayangan
      useEffect(() => {
      setTotalDiskon(diskonglobalnominal1);
    }, [diskonglobalnominal1]);
  
    useEffect(() => {
      setTotalDiskon3(diskontotal-a);
    }, [diskontotal, a]);
  
     //diskon tampil
     useEffect(() => {
      setTotalDiskon2((totaldiskon)/((ppn/100)+1));
    }, [totaldiskon3, totaldiskon, ppn , a]);
  
    //ongkir tampil
    useEffect(() => {
      setOngkir1(ongkir - b);
    }, [ongkir, b]);
  
    useEffect(() => {
      setDp1(dp - c);
    }, [dp, c]);
  
    useEffect(() => {
      setTotalPrice1((qtyTotal)/((ppn/100)+1));
    }, [qtyTotal, ppnnew, ppn ]);
  
  
      // diskonglobalnominal dan persen
      useEffect(() => {
        diskonglobalnominal && diskonglobal === "diskonglobalnominal" && setDiskonGlobalPersen((diskonglobalnominal / qtyTotal ) * 100);
        diskonglobalpersen && diskonglobal === "diskonglobalpersen"  && setDiskonGlobalNominal((qtyTotal) * (diskonglobalpersen/100));
      }, [diskonglobalnominal, qtyTotal, diskonglobal, diskonglobalpersen,diskontotal,diskonglobalpersen]);
  
      // hasil nominal dari PPN
      useEffect(() => {
        setPPNNEW( (qtyTotal - totaldiskon) - ((qtyTotal - totaldiskon) / ((ppn / 100) + 1)));
    }, [qtyTotal,totaldiskon,ppn]);
  
      // hasil grandtotal
    useEffect(() => {
      setGrandTotal(( totalPrice1 - totaldiskon2) + ppnnew - dp1 + ongkir1- a );
    }, [totalPrice1,qtyTotal, totaldiskon2,ppnnew, dp1, ongkir1, a]);

    // useEffect(() => {
    //   setDiskonGlobalNominal1(diskonglobalnominal);
    // }, [diskonglobalnominal]);
  
    //   // diskon bayangan
    //   useEffect(() => {
    //   setTotalDiskon(diskonglobalnominal1);
    // }, [diskonglobalnominal1]);
  
    // useEffect(() => {
    //   setTotalDiskon3(diskontotal-a);
    // }, [diskontotal, a]);
  
    //   //diskon tampil
    //   useEffect(() => {
    //   setTotalDiskon2(totaldiskon - a);
    // }, [totaldiskon3, totaldiskon, a]);
  
    // //ongkir tampil
    // useEffect(() => {
    //   setOngkir1(ongkir - b);
    // }, [ongkir, b]);
  
  
    //   useEffect(() => {
    //   setTotalPrice1(qtyTotal - ppnnew);
    // }, [qtyTotal, ppnnew]);
  
  
    //   // diskonglobalnominal dan persen
    //   useEffect(() => {
    //     diskonglobalnominal && diskonglobal === "diskonglobalnominal" && setDiskonGlobalPersen((diskonglobalnominal / qtyTotal ) * 100);
    //     diskonglobalpersen && diskonglobal === "diskonglobalpersen"  && setDiskonGlobalNominal((qtyTotal) * (diskonglobalpersen/100));
    //   }, [diskonglobalnominal, qtyTotal, diskonglobal, diskonglobalpersen,diskontotal,diskonglobalpersen]);
  
    //     // hasil nominal dari PPN
    //     useEffect(() => {
    //       setPPNNEW( (qtyTotal - totaldiskon) - ((qtyTotal - totaldiskon) / ((ppn / 100) + (100 / 100))));
    //   }, [qtyTotal,totaldiskon,ppn]);
  
    //   // hasil grandtotal
    // useEffect(() => {
    //   setGrandTotal(( totalPrice1 - totaldiskon2) + ppnnew + ongkir1- a );
    // }, [totalPrice1,qtyTotal, totaldiskon2,ppnnew, ongkir1, a]);

    

    // useEffect(() => {
    //   setDiskonGlobalNominal1(diskonglobalnominal);
    // }, [diskonglobalnominal]);
  
    //   // diskon bayangan
    //   useEffect(() => {
    //   setTotalDiskon(diskonglobalnominal1);
    // }, [diskonglobalnominal1]);
  
    // useEffect(() => {
    //   setTotalDiskon3(diskontotal-a);
    // }, [diskontotal, a]);
  
    //   //diskon tampil
    //   useEffect(() => {
    //   setTotalDiskon2(totaldiskon - a);
    // }, [totaldiskon3, totaldiskon, a]);
  
    // //ongkir tampil
    // useEffect(() => {
    //   setOngkir1(ongkir - b);
    // }, [ongkir, b]);
  
  
    //   useEffect(() => {
    //   setTotalPrice1(qtyTotal);
    // }, [qtyTotal]);
  
  
    //   // diskonglobalnominal dan persen
    //   useEffect(() => {
    //     diskonglobalnominal && diskonglobal === "diskonglobalnominal" && setDiskonGlobalPersen((diskonglobalnominal / qtyTotal ) * 100);
    //     diskonglobalpersen && diskonglobal === "diskonglobalpersen"  && setDiskonGlobalNominal((qtyTotal) * (diskonglobalpersen/100));
    //   }, [diskonglobalnominal, diskonglobal,qtyTotal, diskonglobalpersen,diskontotal,diskonglobalpersen]);
  
    //   // hasil nominal dari PPN
    //   useEffect(() => {
    //       setPPNNEW( (qtyTotal - totaldiskon) * (ppn / 100));
    //  }, [qtyTotal,totaldiskon,ppn]);
  
    //   // hasil grandtotal
    // useEffect(() => {
    //   setGrandTotal(( qtyTotal - totaldiskon2) + ppnnew + ongkir1- a );
    // }, [qtyTotal, totaldiskon2,ppnnew, ongkir1, a]);



const getByPajakNominal = (id) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };
  axios
    .get(
      `${process.env.REACT_APP_API_BASE_URL}/pajak/${id}`,
      { headers }
    )
    .then((data) => {;
      setPPN(data.data.response.persentase);
    })
    .catch(function (error) {
      console.log(error);
    });
};

  useEffect(() => {
    getById();
    getDurasiOperasional();
  }, []);
  
const getDurasiOperasional = (id) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };
  axios
    .get(
      `${process.env.REACT_APP_API_BASE_URL}/durasi-operasional/list`,
      { headers }
    )
    .then((data) => {
      setDurasis(data.data.response);
      setDurasi(id)
    })
    .catch(function (error) {
      console.log(error);
    });
};

  const getById = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}/purchase-order/get/${props.match.params.id}`,
        { headers }
      )
      .then((data) => {
        getItemDataSaved();
        getPajak(data.data.response.pajak_id);
        getByPajakNominal(data.data.response.pajak_id);
        getCabang(data.data.response.warehouse_id);
        getbankSupplier(data.data.response.person_id);
        getDurasiOperasional(data.data.response.duration_limit);
        getJangkaWaktu(data.data.response.jangka_waktu);
        setPersonName(data.data.response.person_name);
        setCodeRfq(data.data.response.code_rfq);
        setSales(data.data.response.username);
        setBankSupplier(data.data.response.account_number);
        setAdminPo(data.data.response.admin_po);
        setStatusAp(data.data.response.status_ap);
        setDp(data.data.response.um_nominal)
        setStatusD(data.data.response.status_d)
        setApprove(data.data.response.approve);
        setPaymentMethod(data.data.response.payment_method);
        setKeterangan(data.data.response.keterangan);
        setTotalQty(data.data.response.price_real);
        setDiskonTotal(data.data.response.diskon_total);
        setOngkir(data.data.response.ongkir);
        setLainnya(data.data.response.lainnya);
        setDiskonGlobalNominal(data.data.response.diskon_global_nominal);
        setDiskonGlobalPersen(data.data.response.diskon_global_persen);
        setTanggal(data.data.response.transaction_date);
        setCodePo(data.data.response.po_code)
       
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getJangkaWaktu = (id) => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/jwkredit/list`, { headers })
      .then((data) => {
        setAllJangkaWaktu(data.data.response);
        setJangkaWaktu(id);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getPajak = (id) => {
		const headers = {
			"Content-Type": "application/json",
			Authorization: `bearer ${token}`,
		};
		axios
			.get(`${process.env.REACT_APP_API_BASE_URL}/pajak/list`, { headers })
			.then((data) => {
				setAllPajak(data.data.response);
				setPajak(id);
			})
			.catch(function (error) {
				console.log(error);
			});
	};

  const getItemDataSaved = () => {
    axios.post(`${process.env.REACT_APP_API_BASE_URL}/purchase-order/item`, {

        po_id: props.match.params.id

    }).then(async response => {
        let stateItem = [];

        await Promise.all(response.data.response.map(async (data) => {
            stateItem = [...stateItem, {
                item_id: data.item_id,
                item_name:data.item_name,
                item_code:data.item_code,
                qty: data.qty,
                satuan: data.satuan,
                qty_receiving: data.qty_receiving,
                qty_invoice: data.qty_invoice,
                qty_sisa: data.qty_sisa,
                harga: data.harga,
                diskon_persen: data.diskon_persen,
                diskon_nominal: data.diskon_nominal,
                note : data.note,
                data: {
                    item_name: data.item_name,
                    harga: data.harga
                },
            }];
        }));
        setSavedItems(stateItem);
    })
}

    const getbankSupplier = (id) => {
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
      axios
        .get(`${process.env.REACT_APP_API_BASE_URL}/bank/get-by-person/${id}`, { headers })
        .then((data) => {
          setBankSuppliers(data.data.response);
        })
        .catch(function (error) {
          console.log(error);
        });
    };

    const handleSubmit2 = () => {
      hideAlert();
      setTimeout(() => {EditData()}, 1000);
  };

  function EditData() {
    let dataItems = [];
        savedItems.map((dataItem) => dataItems = [...dataItems, 
            { 
                item_id: dataItem.item_id, 
                qty: dataItem.qty, 
                harga: dataItem.harga,
                diskon_persen: dataItem.diskon_persen,
                diskon_nominal: dataItem.diskon_nominal,
                note : dataItem.note,
            }]);
    let data = {
      warehouse_id : parseInt(cabang),
      username : sales,
      code_rfq: coderfq,
      type : 1,
      approve: parseInt(approve),
      status_ap: parseInt(statusap),
      admin_po: adminpo,
      status_d: parseInt(statusd),
      um_nominal : dp,
      direktur: username,
      duration_limit:parseInt(durasi),
      ongkir: parseFloat(ongkir),
      lainnya:parseFloat(lainnya),
      payment_method: parseInt(paymentmetode),
      jangka_waktu:parseInt(jangkaWaktu),
      diskon_global_nominal: parseFloat(diskonglobalnominal),
      diskon_global_persen: parseInt(diskonglobalpersen),
      keterangan: keterangan ,
      transaction_date : moment(tanggal).format("YYYY-MM-DD 00:00:00"),
      items : dataItems
    };
    axios
        .post(
          `${process.env.REACT_APP_API_BASE_URL}/purchase-order/update/${props.match.params.id}`,
          data,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          } 
        )
        .then(function (response) {
          setSuccessAlert(response.data.message);
          setTimeout(() => (history.push("/admin/pusat/purchase-order/validasi-direktur")), 1000);
        })
        .catch(function (error) {
              setGagalAlert(error.response.data.message);
        });
  }

  const setSuccessAlert = (id) => {
    setAlert(
      <SweetAlert
        success
        showConfirm
        confirmBtnText="Ok"
        title={id}
        onCancel={hideAlert}
        onConfirm={hideAlert}
      />
    )
  }

const setGagalAlert = (id) => {
setAlert(
  <SweetAlert
  danger
  showConfirm
  confirmBtnText="Ok"
  title={id}
  onCancel={hideAlert}
  onConfirm={hideAlert}
  />
);
};

const setQuestionAlert = () => {
setAlert(
  <SweetAlert
  warning
  showCancel
  confirmBtnText="Lanjutkan"
  confirmBtnBsStyle="danger"
  title="Apakah Kamu Yakin?"
  onConfirm={handleSubmit2}
  onCancel={hideAlert}
  focusCancelBtn
  />
);
};

  const hideAlert = () => {
    setAlert(null);
  };

const formatRupiah = (money) => {
  return new Intl.NumberFormat('id-ID',
      { style: 'currency', currency: 'IDR', minimumFractionDigits: 0 }
  ).format(money);
}
  
  const handleSubmit = (e) => {
    {
      EditData();
    }
  };

  return (
    <>
    {alert}
    {penawaranPOs && penawaranPOs === "1" ? (
			<div>
    <SimpleHeader name="Validasi Direktur" parentName="PO" />
    <Container className="mt--6" fluid>
        <Row>
        <div className="col">
          <Card className="bg-secondary shadow">
                    <CardHeader className="bg-white border-0">
                      <h3>Validasi Direktur</h3>
                    </CardHeader>
                    <CardBody>
                      <Row md="12">
                          <Col md="6">
                          {coderfq === "" ? (
                              ""
                            ):(
                            <FormGroup row>
                              <Label
                              className="form-control-label" for="exampleEmail" sm={3}>
                                  Kode Rfq
                              </Label>
                              <Col sm={6}>
                                  <Input
                                  disabled
                                  autoComplete="off"
                                  className="form-control-alternative"
                                  name="person"
                                  type="text"
                                  value={coderfq}
                                  />
                              </Col>
                            </FormGroup>
                            )}
                          <FormGroup row>
                              <Label
                              className="form-control-label"
                                for="exampleEmail"
                                sm={3}
                              >
                                Kode PO
                              </Label>
                              <Col sm={6}>
                                <Input
                                disabled
                                className="form-control-alternative"
                                  type="text"
                                  name="desc"
                                  rows = "4"
                                  placeholder="Masukan Kode Po"
                                  value={codepo}
                                />
                              </Col>                             
                            </FormGroup>
                      <FormGroup row>
                              <Label
                              className="form-control-label"
                                for="exampleEmail"
                                sm={3}
                              >
                                Tanggal
                              </Label>
                              <Col sm={6}>
                                <Input
                                disabled
                                  className="form-control-alternative"
                                  autoComplete="off"
                                  type="date"
                                  placeholder="Masukan Tanggal"
                                  value={tanggal}
                                />
                              </Col>
                            </FormGroup>
                          <FormGroup row>
                              <Label
                              className="form-control-label" for="exampleEmail" sm={3}>
                                  Cabang
                              </Label>
                              <Col sm={6}>
                                  <Input
                                  disabled
                                  autoComplete="off"
                                  className="form-control-alternative"
                                  name="person"
                                  type="select"
                                  value={cabang}
                                  >
                                  <option value=''>Pilih Cabang</option>
                                  {cabangs.map((warehouse2, key) => {
                                      return (
                                          <option key={key} value={warehouse2.id}>
                                          {warehouse2.name}
                                          </option>
                                      );
                                      })}
                                  </Input>
                              </Col>
                            </FormGroup>
                            <FormGroup row>
                              <Label
                              className="form-control-label"
                                for="exampleEmail"
                                sm={3}
                              >
                                Supplier
                              </Label>
                              <Col sm={6}>
                                <Input
                                  disabled
                                  className="form-control-alternative"
                                  name="person"
                                  type="text"
                                  value={personname}
                                >
                                </Input>
                              </Col>
                            </FormGroup>
                            <FormGroup row>
                              <Label
                              className="form-control-label" for="exampleEmail" sm={3}>
                                Bank
                              </Label>
                              <Col sm={6}>
                                <Input
                                  disabled
                                  className="form-control-alternative"
                                  type="select"
                                  value={banksupplier}
                                  >
                                  <option value="" disabled selected hidden>Pilih Bank</option>
                                    {banksuppliers.map((supplier, key) => {
                                      return (
                                          <option key={key} value={supplier.account_number}>
                                            {supplier.bank_name} {supplier.account_number} a/n {supplier.account_name} 
                                          </option>
                                      );
                                    })}
                                  </Input>
                              </Col>
													  </FormGroup>
                            <FormGroup row>
                              <Label
                              className="form-control-label" for="exampleEmail" sm={3}>
                                Diskon
                              </Label>
                              <Col sm={3}>
                                <CurrencyInput
                                  disabled
                                  id="validation-example-2-field"
                                  placeholder="0"
                                  allowDecimals={false}
                                  value={diskonglobalpersen}
                                  className={`form-control`}
                                  suffix={'%'}
                                  step={10}
                                />
                              </Col>
                              <Col sm={3}>
                              <CurrencyInput
                                  disabled
                                  id="validation-example-2-field"
                                  placeholder="Rp0"
                                  allowDecimals={false}
                                  value={diskonglobalnominal}
                                  className={`form-control`}
                                  prefix={'Rp'}
                                  step={10}
                                />
                              </Col>
                            </FormGroup>
                            <FormGroup row>
                              <Label
                              className="form-control-label" for="exampleEmail" sm={3}>
                                PPN
                                <span className="text-danger">*</span>

                              </Label>
                              <Col sm={6}>
                                <Input
                                  disabled
                                  className="form-control-alternative"
                                    type="select"
                                    value={pajak}
                                    >
                                      {allpajak.map((warehouse2, key) => {
                                        return (
                                            <option key={key} value={warehouse2.id}>
                                              {warehouse2.keterangan}
                                            </option>
                                        );
                                      })}
                                </Input>
                              </Col>
                            </FormGroup>
                          </Col>
                          <Col md="6">
                          <FormGroup row>
                              <Label
                              className="form-control-label"
                                for="exampleEmail"
                                sm={4}
                              >
                                Durasi PO
                              </Label>
                              <Col sm={4}>
                                <Input
                                disabled
                                className="form-control-alternative"
                                  name="person"
                                  type="select"
                                  value={durasi}
                                >
                                  <option value="" disabled selected hidden>Pilih Durasi</option>
                                  {durasis.map((person, key) => {
                                      return (
                                        <option key={key} value={person.durasi}>
                                          {person.durasi}
                                        </option>
                                      );
                                    })}
                                </Input>
                              </Col>
                              <Label
                              className="form-control-label"
                                    for="exampleEmail"
                                    sm={3}
                                >
                                    <b>Hari</b>
                                </Label>
                            </FormGroup>
                            <FormGroup row>
                            <Label
                            className="form-control-label"
                              for="exampleEmail"
                              sm={4}
                            >
                              Metode Pembayaran
                            </Label>
                            <Col sm={6}>
                            <Input
                            disabled
                            className="form-control-alternative"
                                name="Tipe Po"
                                type="select"
                                value={paymentmetode}
                              >
                                <option value={""} disabled selected hidden>Pilih Metode Pembayaran</option>
                                <option value={1}>Lunas</option>
                                <option value={2}>Tempo</option>
                                <option value={5}>Dp/Kredit</option>
                              </Input>
                            </Col>
                            </FormGroup>
                            {paymentmetode >  1  ? (
                              <FormGroup row>
                                <Label
                                className="form-control-label"
                                  for="exampleEmail"
                                  sm={4}
                                >
                                  Jangka Waktu
                                </Label>
                                <Col sm={4}>
                                  <Input
                                    disabled
                                    className="form-control-alternative"
                                    type="select"
                                    name="desc"
                                    value={jangkaWaktu}
                                  >
                                  <option value="" disabled selected hidden>Pilih Jangka Waktu</option>
                                  {allJangkaWaktu.map((waktu, key) => {
                                    return (
                                      <option key={key} value={waktu.durasi}>
                                        {waktu.durasi}
                                      </option>
                                    );
                                  })}
                                </Input>
                                </Col> 
                                <Label
                                className="form-control-label" for="exampleEmail" sm={3}>
                                  <b>Hari</b>
                                </Label>
                              </FormGroup>
                            ):(
                              ""
                            )}

{paymentmetode === 5 ? 
                            <FormGroup row>
                              <Label
                              className="form-control-label"
                                for="exampleEmail"
                                sm={4}
                              >
                                Uang Muka
                              </Label>
                              <Col sm={6}>
                              <CurrencyInput
                                  disabled
                                  id="validation-example-2-field"
                                  placeholder="Rp0"
                                  allowDecimals={false}
                                  value={dp}
                                  className={`form-control`}
                                  prefix={'Rp'}
                                  step={10}
                                />
                              </Col>                             
                            </FormGroup>
                            : 
                            ""
                            }
                            <FormGroup row>
                              <Label
                              className="form-control-label"
                                for="exampleEmail"
                                sm={4}
                              >
                                Ongkir
                              </Label>
                              <Col sm={6}>
                              <CurrencyInput
                              disabled
                                  id="validation-example-2-field"
                                  placeholder="Rp0"
                                  allowDecimals={false}
                                  value={ongkir}
                                  className={`form-control`}
                                  prefix={'Rp'}
                                  step={10}
                                />
                              </Col>                             
                            </FormGroup>
                            <FormGroup row>
                              <Label
                              className="form-control-label"
                                for="exampleEmail"
                                sm={4}
                              >
                                Keterangan
                              </Label>
                              <Col sm={6}>
                                <Input
                                disabled
                                className="form-control-alternative"
                                  type="textarea"
                                  name="desc"
                                  rows = "4"
                                  placeholder="Masukan Keterangan"
                                  value={keterangan}
                                />
                              </Col>                             
                            </FormGroup>
                            <FormGroup row>
                            <Col
                              for="exampleEmail"
                              sm={4}
                            >
                              <Label
                              className="form-control-label">
                                Validasi
                              </Label>
                            </Col>
                              <Col sm={7}>
                                <div style={{ display: "flex" }}>
                                  <div className="custom-control custom-radio mb-3">
                                    <Input
                                    className="custom-control-input"
                                      
                                      id="customRadio12"
                                      name="custom-radio-5"
                                      type="radio"
                                      value={5}
                                      checked={statusd === 5}
                                      onChange={() => setStatusD(5)}
                                    />
                                    <Label
                                      className="custom-control-label"
                                      htmlFor="customRadio12"
                                    >
                                    </Label>
                                      <b>SETUJU</b>
                                  </div>
                                  <div
                                    className="custom-control custom-radio mb-3"
                                    style={{ marginLeft: "20px" }}
                                  >
                                    <Input
                                    className="custom-control-input"
                                      id="customRadio13"
                                      name="custom-radio-5"
                                      type="radio"
                                      value={4}
                                      checked={statusd === 4}
                                      onChange={() => setStatusD(4)}
                                    />
                                    <Label
                                      className="custom-control-label"
                                      htmlFor="customRadio13"
                                    >
                                    </Label>
                                    <b>TOLAK</b>
                                  </div>
                                  <div
                                    className="custom-control custom-radio mb-3"
                                    style={{ marginLeft: "20px" }}
                                  >
                                    <Input
                                    className="custom-control-input"
                                      id="customRadio14"
                                      name="custom-radio-5"
                                      type="radio"
                                      value={6}
                                      checked={statusd === 6}
                                      onChange={() => setStatusD(6)}
                                    />
                                    <Label
                                      className="custom-control-label"
                                      htmlFor="customRadio14"
                                    >
                                    </Label>
                                    <b>REVISI</b>
                                  </div>
                                </div>
                              </Col>
                            </FormGroup>
                          </Col>
                      </Row>
                    </CardBody>
              </Card>
              <Card className="bg-secondary shadow">
                    <CardHeader className="bg-white border-0">
                      <h3>Item</h3>
                    </CardHeader>
                    <CardBody>
                          <Table size="sm" responsive>
                            <thead>
                              <tr>
                                <th><b>Nama Item</b></th>
                                <th><b>Kode Item</b></th>
                                <th><b>Harga</b></th>
                                <th><b>Qty</b></th>
                                <th><b>Satuan</b></th>
                                <th><b>Diskon %</b></th>
                                <th><b>Diskon (N)</b></th>
                                <th><b>Sub Total</b></th>
                                <th><b>Note</b></th>
                              </tr>
                            </thead>
                              <tbody>
                              {
                                savedItems.map((savedItem, key) => {
                                  return (
                                    <tr key={key}>
                                      <td>{savedItem.item_name}</td>
                                      <td>{savedItem.item_code}</td>
                                      <td><>{formatRupiah(savedItem.harga)}</></td>
                                      <td>{savedItem.qty}</td>
                                      <td>{savedItem.satuan}</td>
                                      <td><>{savedItem.diskon_persen}</></td>
                                      <td><>{formatRupiah(savedItem.diskon_nominal)}</></td>
                                      <td>
                                      {/* {formatRupiah((((savedItem.harga - savedItem.diskon_nominal) * savedItem.qty) - ((savedItem.harga - savedItem.diskon_nominal) * savedItem.qty) * (ppn/100)))} */}
                                      {formatRupiah(((savedItem.harga - savedItem.diskon_nominal) * savedItem.qty) / (ppn/100 + 100/100))}
                                        
                                        {/* {formatRupiah((savedItem.harga - savedItem.diskon_nominal) * savedItem.qty)} */}
                                        </td>
                                      <td><>{savedItem.note}</></td>

                                    </tr>
                                  )
                                })
                              }
                              </tbody>
                          </Table>
                        </CardBody>
                        <CardFooter >
                          <Row md="12">
                            <Col md="6">
                            </Col>
                            <Col md="2">
                            </Col>
                            <Col md="4">
                              <FormGroup row>
                              <Label
                              className="form-control-label"
                                for="exampleEmail"
                                sm={4}
                                size="small"
                              >
                                <b>Total</b>
                              </Label>
                              <Col sm={6}>
                              <Input
                                className="form-control-alternative"
                                disabled
                                style={{fontWeight: 'bold'}}
                                type="text"
                                name="barcode"
                                placeholder="Harga Total"
                                value={"Rp." + totalPrice1.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,") + ",-"}
                                />
                              </Col>
                              </FormGroup>
                              <FormGroup row>
                              <Label
                              className="form-control-label"
                                for="exampleEmail"
                                sm={4}
                              >
                                <b>Diskon</b>
                              </Label>
                              <Col sm={6}>
                              <Input
                                className="form-control-alternative"
                                style={{fontWeight: 'bold'}}
                                disabled
                                type="text"
                                name="barcode"
                                placeholder="Diskon"
                                value={"Rp." + totaldiskon2.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,") + ",-"}
                                />
                              </Col>
                              </FormGroup>
                              <FormGroup row>
                              <Label
                              className="form-control-label"
                                for="exampleEmail"
                                sm={4}
                              >
                                <b>PPN</b>
                              </Label>
                              <Col sm={6}>
                              <Input
                                className="form-control-alternative"
                                disabled
                                style={{fontWeight: 'bold'}}
                                type="text"
                                name="barcode"
                                placeholder="PPN"
                                value={"Rp." + ppnnew.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,") + ",-"}
                                />
                              </Col>
                              </FormGroup>
                              <FormGroup row>
                              <Label
                              className="form-control-label"
                                for="exampleEmail"
                                sm={4}
                              >
                                <b>Ongkir</b>
                              </Label>
                              <Col sm={6}>
                              <Input
                                className="form-control-alternative"
                                disabled
                                style={{fontWeight: 'bold'}}
                                type="text"
                                name="barcode"
                                value={"Rp." + ongkir1.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,") + ",-"}
                                />
                              </Col>
                              </FormGroup>
                              <FormGroup row>
                              <Label
                              className="form-control-label"
                                for="exampleEmail"
                                sm={4}
                              >
                                <b>Uang Muka</b>
                              </Label>
                              <Col sm={6}>
                              <Input
                                className="form-control-alternative"
                                disabled
                                style={{fontWeight: 'bold'}}
                                type="text"
                                name="barcode"
                                placeholder="Uang Muka"
                                value={"Rp." + dp1.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,") + ",-"}
                                />
                              </Col>
                              </FormGroup>
                              <FormGroup row>
                              <Label
                              className="form-control-label"
                                for="exampleEmail"
                                sm={4}
                              >
                                <b>Grand Total</b>
                              </Label>
                              <Col sm={6}>
                              <Input
                              className="form-control-alternative"
                                disabled
                                type="text"
                                name="barcode"
                                style={{fontWeight: 'bold'}}
                                placeholder="Grand Total"
                                value={"Rp." + grandtotal.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,") + ",-"}
                                />
                              </Col>
                              </FormGroup>
                            </Col>
                          </Row>
                        </CardFooter>
                    <CardFooter>
                          <Link className="btn btn-info" to="/admin/pusat/purchase-order/validasi-direktur">
                              Kembali
                          </Link>
                          <Button color="danger" onClick={()=>setQuestionAlert()}>
                              Simpan
                          </Button>
                    </CardFooter>
              </Card>
          </div>
        </Row>
    </Container>
    </div>
    ):(
      <Halaman404 />
    )}
    </>
  );
}