/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { Card, Row, Col, CardBody, Input, Button,CardTitle , FormGroup, CardHeader, Container, Label, Badge } from "reactstrap";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import axios from "axios";
import SweetAlert from "react-bootstrap-sweetalert";
import ModalCetakAbsensi from "./ModalCetakAbsensi";

// Marker icons
const masukIcon = new L.Icon({
  iconUrl: "https://i.ibb.co/XYf5GGc/masuk.png",
  iconSize: [40, 40],
});

const keluarIcon = new L.Icon({
  iconUrl: "https://i.ibb.co/hCtKVzc/pulang.png",
  iconSize: [40, 40],
});

const AbsensiHrd = () => {
  const token = localStorage.token;
  const warehouse = localStorage.warehouse;
  const [latmasuk, setLatMasuk] = useState([]);
  const [latkeluar, setLatKeluar] = useState([]);
  const [allPenawaranPo, setAllPenawaranPo] = useState([]);
  const [tanggal, setTanggal] = useState("");
  const [name, setName] = useState("");
  const [cabang, setCabang] = useState(warehouse);
  const [cabangs, setCabangs] = useState([]);
  const [alert, setAlert] = useState(null);
  const [openModalCetak, setOpenModalCetak] = useState(false);
  const [dataModalCetak, setDataModalCetak] = useState({ id: 0 });
  const [mapCenter, setMapCenter] = useState([-7.3106144, 112.6841525]);

  const displayModalCetak = (id,status_kerja) => {
    setDataModalCetak({
      id: id,
      status_kerja : status_kerja,
    });
    setOpenModalCetak(true);
  };

  const toggleModal = () => setOpenModalCetak(!openModalCetak);

  const updateDataTable = (page, tanggal, cabang, name) => {
    getPenawaranPo(page, tanggal, cabang, name);
    getCheckinAbsen(page, tanggal, cabang, name);
    getCheckoutAbsen(page, tanggal, cabang, name);
    setTanggal(tanggal);
    setCabang(cabang);
    setName(name);
  }
  
  useEffect(() => {
    const today = new Date().toISOString().split("T")[0];
    setTanggal(today);
    getPenawaranPo(1, today, cabang, name);
    getCheckinAbsen(1, today, cabang, name);
    getCheckoutAbsen(1, today, cabang, name);
    getCabang();
  }, []);

  const getCabang = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/warehouse/list/all`, { headers })
      .then((data) => setCabangs(data.data.response))
      .catch((error) => console.error(error));
  };


  // const handlemit = () => {(getPenawaranPo(),getCheckinAbsen(),getCheckoutAbsen())}

  // fungsi dari ambil data
  const getPenawaranPo = (page, search_date = "", warehouse_id = "", name = ""
  ) => {
    let filter = { page: page, per_page: 500
    };
    if (search_date !== "") {
      filter = Object.assign(filter, { search_date: search_date});
    }
    if (warehouse_id !== "") {
      filter = Object.assign(filter, { warehouse_id: warehouse_id});
    }
    if (name !== "") {
      filter = Object.assign(filter, { name: name });
    }
    const data = filter;
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/absensi-karyawan/page`, data, {
        headers,
      })
      .then((data) => {
        // setImage(data.data.response[0].karyawan_image)
        setAllPenawaranPo(data.data.response);
      })
      .catch(function (error) {
        setAllPenawaranPo(error.response.data.response);
        // setGagalAlert(error.response.data.message);
  });
}


  const getCheckinAbsen = ( page, search_date = "", warehouse_id = "", name = ""
  ) => {
    let filter = { page: page, per_page: 500
    };
    if (search_date !== "") {
      filter = Object.assign(filter, { search_date: search_date });
    }
    if (warehouse_id !== "") {
      filter = Object.assign(filter, {warehouse_id: warehouse_id });
    }
    if (name !== "") {
      filter = Object.assign(filter, {name: name });
    }
    const data = filter;
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/absensi-karyawan/check-in/page`, data, {
        headers,
      })
      .then((data) => {
        setLatMasuk(data.data.response);

      })
      .catch(function (error) {
        // setGagalAlert(error.data.data.message);
  });
}

  const getCheckoutAbsen = (page, search_date = "", warehouse_id = "", name = ""
  ) => {
    let filter = { page: page, per_page: 500
    };
    if (search_date !== "") {
      filter = Object.assign(filter, { search_date: search_date });
    }
    if (warehouse_id !== "") {
      filter = Object.assign(filter, {warehouse_id: warehouse_id });
    }
    if (name !== "") {
      filter = Object.assign(filter, {name: name });
    }
    const data = filter;
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/absensi-karyawan/check-out/page`, data, {
        headers,
      })
      .then((data) => {
        setLatKeluar(data.data.response);

      })
      .catch(function (error) {
        // setGagalAlert(error.data.data.message);
  });
}

  return (
    <div>
      {alert}
      <SimpleHeader name="Absensi Karyawan" parentName="SO" />
      <Container className="mt--6" fluid>
        <Row>
          <Col md="9">
            <Card>
              <CardHeader>
                <Row md="12">
                  <Col md="3">
                    <FormGroup>
                      <Label className="form-control-label">Tanggal</Label>
                      <Input type="date" value={tanggal} onChange={(e) => updateDataTable(1,e.target.value,cabang,name)} />
                    </FormGroup>
                  </Col>
                  <Col md="3">
                    <FormGroup>
                      <Label className="form-control-label">Cabang</Label>
                      <Input type="select" value={cabang} onChange={(e) => updateDataTable(1,tanggal, e.target.value, name)}>
                        {cabangs.map((warehouse2, key) => (
                          <option key={key} value={warehouse2.id}>
                            {warehouse2.name}
                          </option>
                        ))}
                      </Input>
                    </FormGroup>
                  </Col>
                  <Col md="3">
                    <FormGroup>
                      <Label className="form-control-label">Nama</Label>
                      <Input type="text" placeholder="Masukan Nama" value={name} onChange={e => updateDataTable(1,tanggal,cabang, e.target.value)} />
                    </FormGroup>
                  </Col>
                </Row>
              </CardHeader>
              <div style={{ height: "70vh", width: "100%" }}>
                <MapContainer center={mapCenter} zoom={13} style={{ height: "100%", width: "100%" }}>
                  <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                  {latmasuk.map((point, index) => (
                    <Marker key={index} position={[point.latitude_dtg, point.longitude_dtg]} icon={masukIcon}>
                      <Popup>Check-in: {point.name}</Popup>
                    </Marker>
                  ))}
                  {latkeluar.map((point, index) => (
                    <Marker key={index} position={[point.latitude_plg, point.longitude_plg]} icon={keluarIcon}>
                      <Popup>Check-out: {point.name}</Popup>
                    </Marker>
                  ))}
                </MapContainer>
              </div>
            </Card>
          </Col>
          {/* Card Right */}
          <Col md="3">
                <Card>
                  <CardHeader>
                  <h3>Riwayat Absen</h3>
                  </CardHeader>
                  {allPenawaranPo === null ? (
                     <CardHeader>
                          <center><h3>DATA NOT FOUND</h3></center>
                      </CardHeader>
                    ):(
                    <div style={{ height: '  73vh', overflowY: "auto" ,cursor: "pointer" }}>
                            {allPenawaranPo.map((v) => (
                            <Card style={{ marginBottom: "0rem"}} onClick={() => displayModalCetak(v.id, v.status_kerja)}>
                              <CardBody>
                                <Row>
                                  <div className="col">
                                    <CardTitle className="h5 text-uppercase text-muted mb-0 text-black">
                                    {v.check}&nbsp;{v.status_kerja === 1 ? (
                                      <Badge color="success">{v.keterangan}</Badge>
                                  ) : v.status_kerja === 2 ? (
                                      <Badge color="danger">{v.keterangan}</Badge>
                                  ): <Badge color="warning">{v.keterangan}</Badge>}
                                    </CardTitle>
                                    <span className="h5 font-weight-bold mb-0 text-black">
                                    {v.name}
                                    </span>
                                  </div>
                                  <Col className="col-auto">
                                  <a
                                    className="avatar rounded-circle mr-3"
                                    href="#pablo"
                                    onClick={e => e.preventDefault()}
                                  >
                                    {v.karyawan_image === "" ?(
                                          <img
                                            alt=""
                                            src={"https://i.ibb.co/bH9JpRW/profil.png"}
                                          /> 
                                        ):(
                                            <img
                                                alt=""
                                                src={v.karyawan_image}
                                              />
                                        )}
                                  </a>
                                      
                                  </Col>
                                </Row>
                              </CardBody>
                            </Card>
                            ))
                        } 
                    </div>
                    )}
                </Card>
              </Col>
            </Row>
        {openModalCetak && (
        <ModalCetakAbsensi
          open={openModalCetak}
          data={dataModalCetak}
          toggle={toggleModal}
        />
        )}
        </Container>
    </div>
  );
};

export default AbsensiHrd;
