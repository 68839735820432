/*eslint-disable*/
import React, { useEffect, useState } from "react";
import {Card,CardBody,Label,Badge,FormGroup,Row,Input,CardHeader,Col,Button,Table,Container,Modal, ModalHeader, ModalBody} from "reactstrap";
import { Link } from "react-router-dom";
import axios from "axios";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import Halaman404 from "views/404";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import SweetAlert from "react-bootstrap-sweetalert";

export default function Userss() { 
    const [alert, setAlert] = useState(null);
	const [datalog, setDataLog] = useState([])
	const [rowIndex, setRowIndex] = useState(0);
	const toggle1 = () => setModal1(!modal1);
	const [modal1, setModal1] = useState(false);
    const allInfo = JSON.parse(localStorage.allinfo);
    const createButton = String(allInfo.privileges.filter((i) => i.privilege_name === "Sub Users").map((p) => p.create_access));
    const UpdateButton = String(allInfo.privileges.filter((i) => i.privilege_name === "Sub Users").map((p) => p.update_access));
	const users = String(allInfo.privileges.filter((i) => i.privilege_name === "Sub Users").map((p) => p.read_access));
    const token = localStorage.token;
    const [usernameusers, setUsernameUsers] = useState("");
    const [nama,setNama] = useState("");
    const [itemId, setItemId] = useState(1);
	const [allItem, setAllItem] = useState([]);
    const [savedItems, setSavedItems] = useState([]);
	const [query, setQuery] = useState(null);
	const [isSearchShow, setIsSearchShow] = useState(false);
	const headers = { Authorization: `Bearer ${token}` };
    const redirectPrefix = `/admin/user/edit/`;
    const redirectPrefix1 = `/admin/user/new-password/`;
    const [email, setEmail] = useState("");
    const [status, setStatus] = useState("");
    const [warehouse,setWarehouse] = useState("");
    const [warehouses,setWarehouses] = useState([]);
    const [page, setPage] = useState(1);
	const [totalPages, setTotalPages] = useState(1);

    useEffect(() => {
		getItems();
	}, [page]);

	//get list item berdasarkan warehouse
	const getItems = async () => {
		let filter = {
			page: page,
			per_page: 1,
		};
		const data = filter;
		const res = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/users`, data, { headers });
		if(res.data.response.id !== null){
			getById(res.data.response[0].id);
			setItemId(res.data.response[0].id);
			setTotalPages(res.data.total_page);
		}
	};

	//cari item
	useEffect(() => {
		const getData = setTimeout(async() => {
			if(query != ''){
				axios
				.post(`${process.env.REACT_APP_API_BASE_URL}/users`, { name: query , per_page: 10 },{ headers })
				.then((res) => {
					setAllItem(res.data);
					setIsSearchShow(true);
				})
				.catch(function (error) {
					setAllItem(error.response.data.response);
					setIsSearchShow(true);

				});
			}
		  }, 1000)
		  return () => clearTimeout(getData)
	}, [query]);


	const getById = (id) => {
        const headers = {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        };
        axios
          .get(
            `${process.env.REACT_APP_API_BASE_URL}/users/get/${id}`,
            { headers }
          )
          .then((data) => {
            setWarehouse(data.data.response.warehouse_id)
            setUsernameUsers(data.data.response.username)
            setNama(data.data.response.name)
            setEmail(data.data.response.email)
            setStatus(data.data.response.active_flag);
            getItemDataSaved(data.data.response.id);
            getWarehouse()
          })
          .catch(function (error) {
            console.log(error);
          });
      };


      const getWarehouse = () => {
        const headers = {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
        };
        axios
            .get(
            `${process.env.REACT_APP_API_BASE_URL}/warehouse/list/all`,
            { headers }
            )
            .then((data) => {
                setWarehouses(data.data.response);
            })
            .catch(function (error) {
            console.log(error);
            });
        };

      const getItemDataSaved = (id) => {
        const headers = {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          };

        axios.post(`${process.env.REACT_APP_API_BASE_URL}/users/list-privileges`, {

            user_id: id
    
        }, { headers }).then(async response => {
            let stateItem = [];
    
            await Promise.all(response.data.response.map(async (data) => {
                stateItem = [...stateItem, {
                    id: data.id,
                    user_id: data.user_id,
                    privilege_id: data.privilege_id,
                    privilege_name: data.privilege_name,
                    create_access: data.create_access,
                    read_access: data.read_access,
                    update_access: data.update_access,
                    delete_access: data.delete_access,
                    data: {
                        user_id: data.user_id,
                        privilege_id: data.privilege_id,
                        privilege_name: data.privilege_name,
                        create_access: data.create_access,
                        read_access: data.read_access,
                        update_access: data.update_access,
                        delete_access: data.delete_access
                    },
                }];
            }));
            setSavedItems(stateItem);
        })
    }

    function EditData() {
       
        let data = {username : usernameusers};
        axios.post(
              `${process.env.REACT_APP_API_BASE_URL}/users/logout`,
              data,{headers: {Authorization: `Bearer ${token}`},}
            )
            .then(function (response) {
              setSuccessAlert(response.data.message);
              getById()
                    // setTimeout(() => (history.push("/admin/sales-order/validasi-kepala-purchasing")), 1000);
            })
            .catch(function (error) {
                  setGagalAlert(error.response.data.message);
            });
      }
    
      const setSuccessAlert = (id) => {
        setAlert(
          <SweetAlert
            success
            showConfirm
            timeout={2000}
            confirmBtnText="Ok"
            title={id}
            onCancel={hideAlert}
            onConfirm={hideAlert}
          />
        )
      }
    
    const setQuestionAlert = () => {
    setAlert(
      <SweetAlert
      warning
      showCancel
      confirmBtnText="Lanjutkan"
      confirmBtnBsStyle="danger"
      title="Apakah Kamu Yakin?"
      onConfirm={EditData}
      onCancel={hideAlert}
      focusCancelBtn
      />
    );
    };

    const searchShow = (item) => {
        getById(item.id)
        // setPage(item.id)
		setItemId(item.id);
		setIsSearchShow(false);
	};

    const getDataLog = (id) => {
		let filter = { 
		  transaction : id,
		  features : "MASTER13",
		  warehouse_id : 0
		}
		const data = filter;
		const headers = {
		  'Content-Type': 'application/json',
		  Authorization: `Bearer ${token}`
		  }
		axios.post(`${process.env.REACT_APP_API_BASE_URL}/riwayat-data/info`, data, { headers
		})
		.then(data => {
		  setDataLog(data.data.response);
		  setModal1(!modal1);
	  
		})
		  .catch(function (error) {
		  setGagalAlert(error.response.data.message);
		  })
		}
	  
		const setGagalAlert = (id) => {
		setAlert(
		<SweetAlert
		danger
		showConfirm
        timeout={2000}
		confirmBtnText="Ok"
		title={id}
		onCancel={hideAlert}
		onConfirm={hideAlert}
		/>
		);
		};
		
		const hideAlert = () => {
		  setAlert(null);
		};

        const handlePrevious = () => {
            if (page > 1) setPage((prev) => prev - 1);
        };
        
        const handleNext = () => {
            if (page < totalPages) setPage((prev) => prev + 1);
        };
        
        const handleFirst = () => {
            if (page !== 1) setPage(1);
        };
        
        const handleEnd = () => {
            if (page !== totalPages) setPage(totalPages);
        };

	return (
		<>
        {alert}
        {users && users === "1" ? (
			<div>
				<SimpleHeader name="Akses User" parentName="Master" />
				<Container className="mt--6" fluid>
					<Row>
						<div className="col">
								<Card className="position-sticky boxShadow" style={{ top: 0, zIndex: "5" }}>
									<CardBody className="ml-3 pb-0">
										<Row md="12">
                                            <Col md="5 d-lg-block d-none">
                                            {/* <Button onClick={() => setPage(1)} disabled={page === 1} color="danger" type="button">
                                                First
                                            </Button>
                                            <Button onClick={() => setPage((page) => page - 1)} disabled={page === 1} color="success" type="button">
                                            <i className="fa fa-angle-left" /> Prev
                                            </Button>
                                            <Button onClick={() => setPage((page) => page + 1)} disabled={page >= totalPages} color="success" type="button">
                                                Next <i className="fa fa-angle-right" />
                                            </Button>
                                            <Button onClick={() => setPage(totalPages)} disabled={page == totalPages} color="warning" type="button">
                                                End
                                            </Button> */}
                                            <Button onClick={handleFirst} disabled={page === 1} color="danger">
                                                First
                                            </Button>
                                            <Button onClick={handlePrevious} disabled={page === 1} color="success">
                                                <i className="fa fa-angle-left" /> Prev
                                            </Button>
                                            <Button onClick={handleNext} disabled={page >= totalPages} color="success">
                                                Next <i className="fa fa-angle-right" />
                                            </Button>
                                            <Button onClick={handleEnd} disabled={page === totalPages} color="warning">
                                                End
                                            </Button>
                                            </Col>
                                            <Col md="3">
                                                <FormGroup row>
                                                    <Col sm={10}>
                                                        <Input
                                                            placeholder="Search User"
                                                            type="search"
                                                            value={query}
                                                            onChange={(e) => setQuery(e.target.value)}
                                                        />
                                                    </Col>
                                                    <Col sm={2}>
                                                    </Col>
                                                </FormGroup>
                                            </Col>
											<Col md="4 d-lg-block d-none">
												<div style={{ textAlign: "right" }}>
                                                <Button color="warning" onClick={() => (getDataLog(itemId))}>
												<i className="fa fa-info-circle" aria-hidden="true"></i>
												</Button>
                                                {UpdateButton && UpdateButton === "1" && (
                                                    <Link className="btn btn-info"
														to={redirectPrefix + itemId}
													>
														<i className="fas fa-user-edit" /> Edit
													</Link>
                                                )}
                                                     {createButton && createButton === "1" && (
                                                    <Link className="btn btn-danger" to="/admin/user/create">
                                                        <i className="fas fa-plus" /> Tambah
													</Link>
                                                     )}
												</div>
											</Col>
										</Row>
									</CardBody>
								</Card>
								{/* Search card */}
								{isSearchShow && query && (
									<Card className="boxShadow" style={{ top: 100, zIndex: "3", maxHeight: "15.5rem", overflowY: "auto", paddingTop: "1rem", position: "sticky" }}>
										<div style={{ position: "absolute", top: "2.5px", right: "1rem", cursor: "pointer", fontSize: "2rem" }}>
											<i className="fas fa-window-close text-danger" onClick={() => setIsSearchShow(false)}></i>
										</div>
										<span className="text-center mb-3">
											<b>Pencarian berdasarkan:</b> {query}
										</span>
										{allItem?.response ? (
											allItem.response.map((item) => (
												<CardBody key={item.id} className="bgSearch border-bottom p-2 mb-2" style={{minHeight: 'auto'}} onClick={() => {searchShow(item); setQuery('')}}>
													<div>
														<b>Nama :</b> {item.name}
													</div>
													<div>
														<b>Kode User:</b> {item.user_code ? item.user_code : "(Not available)"}
													</div>
												</CardBody>
											))
										) : (
											<div className="text-center mb-3 text-danger">User "{query}" tidak ada bosku!</div>
										)}
									</Card>
								)}
            <Card className="position-sticky bg-secondary boxShadow" style={{ top: 30, zIndex: "1" }}>
                <CardHeader className="bg-white border-0">
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <h3>User</h3>
                          <div style={{ textAlign: 'right' }}>
                            {status === 1 ? 
                                        <h1>
                                        <Badge color="success">
                                            AKTIF
                                        </Badge>
                                        </h1>:
                                        <h1>
                                        <Badge color="danger">
                                            TIDAK AKTIF
                                        </Badge>
                                        </h1>
                                        }
                          </div>
                      </div>
                </CardHeader>
                <Row md="12">
					<Col md="6">
                        <CardBody>
                            <FormGroup row>
                                <Label
                                className="form-control-label"
                                for="exampleEmail" sm={4}>
                                    Username
                                </Label>
                                <Col sm={7}>
                                    <Input
                                        disabled
                                        className="form-control-alternative"
                                        type="text"
                                        name="itemCode"
                                        placeholder="Masukan Username"
                                        value={usernameusers || ""} 
                                        onChange={(e) => {
                                            setUsernameUsers(e.target.value);
                                        }}
                                    />
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label
                                 className="form-control-label"
                                for="exampleEmail" sm={4}>
                                    Cabang
                                </Label>
                                <Col sm={7}>
                                    <Input
                                    disabled
                                    className="form-control-alternative"
                                    name="person"
                                    type="select"
                                    value={warehouse || ""}
                                    onChange={(e) => {
                                        setWarehouse(e.target.value);
                                    }}
                                    >
                                    <option value=''>Pilih Cabang</option>
                                    {warehouses.map((warehouse2, key) => {
                                        return (
                                            <option key={key} value={warehouse2.id}>
                                            {warehouse2.name}
                                            </option>
                                        );
                                        })}
                                    </Input>
                                </Col>
                            </FormGroup>
                            <div className="d-lg-block d-none">
                            <FormGroup row>
                                <Label
                                 className="form-control-label"
                                for="exampleEmail" sm={4}>
                                    Nama
                                </Label>
                                <Col sm={7}>
                                    <Input
                                    disabled
                                        className="form-control-alternative"
                                        type="text"
                                        name="barcode"
                                        placeholder="Masukan Nama"
                                        value={nama || ""}
                                        onChange={(e) => {
                                            setNama(e.target.value);
                                        }}
                                    />
                                </Col>
                            </FormGroup>
                            </div>
                        </CardBody>
                    </Col>
                    <Col md="6">
                        <CardBody>
                            <div className="d-lg-block d-none">
                        <FormGroup row>
                                <Label
                                 className="form-control-label"
                                for="exampleEmail" sm={4}>
                                    Email
                                </Label>
                                <Col sm={7}>
                                    <Input
                                        disabled
                                        className="form-control-alternative"
                                        type="text"
                                        name="email"
                                        placeholder="Masukan Email"
                                        value={email || ""}
                                        onChange={(e) => {
                                            setEmail(e.target.value);
                                        }}
                                    />
                                </Col>
                            </FormGroup>
                            </div>
                            <FormGroup row>
                                <Col sm={4}>
                                <Label
                                 className="form-control-label"
                                >
                                Password
                                </Label>
                              </Col>
                                <Col sm={7}>
                                    <Link className="btn btn-info sm"
                                        to={redirectPrefix1 + itemId}
                                    >
                                        <i className="ni ni-lock-circle-open" /> New Password
                                    </Link>
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Col sm={4}>
                                    <Label
                                     className="form-control-label"
                                    >
                                    Logout
                                    </Label>
                              </Col>
                                <Col sm={7}>
                                <Button color="danger" onClick={()=> setQuestionAlert()}>
                                    <i className="fas fa-sign-out-alt"></i> Logout
                                </Button>
                                </Col>
                            </FormGroup>
                        </CardBody>
                    </Col>
                </Row>
                                </Card>
                                <Card className="bg-secondary shadow">
                                    <CardBody>
                                    <Table size="sm" responsive>
                                        <thead>
                                        <tr>
                                            <th>Menu</th>
                                            <th>View</th>
                                            <th>Create</th>
                                            <th>Update</th>
                                            <th>Delete</th>
                                            <th></th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {savedItems.map((savedItem, key) => {
                                            return (
                                            <tr key={key}>
                                                <td>{savedItem.data.privilege_name}</td>
                                                {/* Read */}
                                                <td>
                                                <label
                                                className="custom-toggle custom-toggle-primary">
                                                    <Input
                                                    disabled
                                                    readOnly
                                                    type="checkbox"
                                                    checked={
                                                        savedItem.read_access == 1 ? true : false
                                                    }
                                                    value={savedItem.read_access || ""}
                                                    onChange={(e) => {
                                                        handleEditQty(key, e.target.value, "read");
                                                    }}
                                                    />
                                                    <span
                                                    className="custom-toggle-slider rounded-circle"
                                                    data-label-off="NO"
                                                    data-label-on="YES"
                                                    ></span>
                                                </label>
                                                </td>
                                                {/* Create */}
                                                <td>
                                                <label
                                                className="custom-toggle custom-toggle-primary">
                                                    <Input
                                                    disabled
                                                    type="checkbox"
                                                    checked={
                                                        savedItem.create_access == 1 ? true : false
                                                    }
                                                    value={savedItem.create_access || ""}
                                                    onChange={(e) => {
                                                        handleEditQty(
                                                        key,
                                                        e.target.value,
                                                        "create"
                                                        );
                                                    }}
                                                    />
                                                    <span
                                                    className="custom-toggle-slider rounded-circle"
                                                    data-label-off="NO"
                                                    data-label-on="YES"
                                                    ></span>
                                                </label>
                                                </td>
                                                
                                                {/* Update */}
                                                <td>
                                                <label
                                                className="custom-toggle custom-toggle-primary">
                                                    <Input
                                                    disabled
                                                    type="checkbox"
                                                    checked={
                                                        savedItem.update_access == 1 ? true : false
                                                    }
                                                    value={
                                                        savedItem.update_access || ""
                                                    }
                                                    onChange={(e) => {
                                                        handleEditQty(
                                                        key,
                                                        e.target.value,
                                                        "update"
                                                        );
                                                    }}
                                                    />
                                                    <span
                                                    className="custom-toggle-slider rounded-circle"
                                                    data-label-off="NO"
                                                    data-label-on="YES"
                                                    ></span>
                                                </label>
                                                </td>
                                                {/* delete */}
                                                <td>
                                                <label
                                                className="custom-toggle custom-toggle-primary">
                                                    <Input
                                                    disabled
                                                    type="checkbox"
                                                    checked={
                                                        savedItem.delete_access == 1 ? true : false
                                                    }
                                                    value={savedItem.delete_access || ""}
                                                    onChange={(e) => {
                                                        handleEditQty(
                                                        key,
                                                        e.target.value,
                                                        "delete"
                                                        );
                                                    }}
                                                    />
                                                    <span
                                                    className="custom-toggle-slider rounded-circle"
                                                    data-label-off="NO"
                                                    data-label-on="YES"
                                                    ></span>
                                                </label>
                                                </td>
                                            </tr>
                                            );
                                        })}
                                        </tbody>
                                    </Table>
                                    </CardBody>
                                </Card>
						</div>
					</Row>
				</Container>
                <Modal isOpen={modal1} toggle={toggle1} style={{ minWidth: "70%", top: "5%"  }}>
        <ModalHeader toggle={toggle1} className="text-center" align="center"></ModalHeader>
        <ModalBody align="center">
          <h2><b>Log Info</b></h2> 
          <CardBody>
          <ToolkitProvider 
              rowNumber={rowIndex}
              data={datalog}
              keyField="id"
              columns={[
                  {
                  dataField: "no",
                  text: "#",
                  sort: true,
                  page: 1,
                  formatter: (cell, row, index) => {
                      let currentRow = ++index;
                      return currentRow + rowIndex;
                  },
                  },
				  {
					dataField: "api_hit",
					text: "Tanggal Update",
					sort: true,
					},
                  {
                  dataField: "username",
                  text: "PIC",
                  sort: true,
                  },
                  {
                  dataField: "lost_data",
                  text: "Data Lama",
                  sort: true,
                  },
                  {
                  dataField: "show_data",
                  text: "Data Baru",
                  sort: true,
                  },
              ]}
              >
              {(props) => (
                  <div className="py-4 table-responsive">
                  <BootstrapTable
                      remote
                      {...props.baseProps}
                      bootstrap4={true}
                      bordered={false}
                      hover={true}
                  />
                  </div>
              )}
              </ToolkitProvider>
          </CardBody>
          <Button color="secondary" onClick={toggle1}>
            Kembali
          </Button>
        </ModalBody>
      </Modal> 
			</div>
        ):(
            <Halaman404 />
        )}
		</>
	);
}
