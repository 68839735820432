/*eslint-disable*/
import React, { useEffect, useState } from "react";
import {Card,CardBody,Label,FormGroup,Row,Input,CardHeader,Col,Button,Container,CardGroup,DropdownMenu,DropdownItem,UncontrolledDropdown,DropdownToggle,Modal, ModalHeader, ModalBody} from "reactstrap";
import { Link } from "react-router-dom";
import axios from "axios";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import Halaman404 from "views/404";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import SweetAlert from "react-bootstrap-sweetalert";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";

export default function EditItem() {
	const token = localStorage.token;
	const [alert, setAlert] = useState(null);
	const [datalog, setDataLog] = useState([])
	const [rowIndex, setRowIndex] = useState(0);
	const toggle1 = () => setModal1(!modal1);
	const [modal1, setModal1] = useState(false);
	const [page, setPage] = useState(1);
	const [totalPages, setTotalPages] = useState(1);
	const [name, setName] = useState("");
	const [address, setAddress] = useState("");
	const [city, setCity] = useState([]);
	const [province, setProvince] = useState([]);
	const [phone, setPhone] = useState("");
	const [image, setImage] = useState([]);
	const [provinces, setProvinces] = useState([]);
	const [citys, setCitys] = useState([]);
	const [itemId, setItemId] = useState(1);
	const [allItem, setAllItem] = useState([]);
	const [query, setQuery] = useState(null);
	const [isSearchShow, setIsSearchShow] = useState(false);
	const headers = { Authorization: `Bearer ${token}` };
	const [code, setCode] = useState("");
	const redirectPrefix = `/admin/warehouse/edit/`;
	const redirectPrefix1 = `/admin/warehouse/edit/gambar/`;
	const allInfo = JSON.parse(localStorage.allinfo);
	const Create = String(allInfo.privileges.filter((i) => i.privilege_name === "Sub Cabang").map((p) => p.create_access));
	const Update = String(allInfo.privileges.filter((i) => i.privilege_name === "Sub Cabang").map((p) => p.update_access));
	const cabang = String(allInfo.privileges.filter((i) => i.privilege_name === "Sub Cabang").map((p) => p.read_access));
	const [long, setLong] = useState(0);
	const [lat, setLat] = useState(0);
	const [radius, setRadius] = useState(null);
	const [onmap, setOnmap] = useState()
	// const mapCenter = useState([-7.3106144, 112.6841525]);
	// const [mapCenter, setMapCenter] = useState([-7.3106144, 112.6841525]);
	const [mapCenter, setMapCenter] = useState({lat: -7.3106144, lng: 112.6841525});

	const masukIcon = new L.Icon({
		iconUrl: "https://i.ibb.co/0JhYD5r/cabang.png",
		iconSize: [40, 40],
	  });
	  
	const successCallback = (position) => {};
	const errorCallback = (error) => {};
	navigator.geolocation.getCurrentPosition(successCallback, errorCallback);

	useEffect(() => {
		getItems();
	}, [page]);

	//get list item berdasarkan warehouse
	const getItems = async () => {
		let filter = {
			page: page,
			per_page: 1,
		};
		const data = filter;
		const res = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/warehouse/all`, data, { headers });
		if (res.data.response.id !== null) {
			getById(res.data.response[0].id);
			setItemId(res.data.response[0].id);
			setTotalPages(res.data.total_page);
		}
	};

	//cari item
	useEffect(() => {
		const getData = setTimeout(async () => {
			if (query != '') {
				axios
					.post(`${process.env.REACT_APP_API_BASE_URL}/warehouse/all`, { warehouse_name: query, per_page: 10 }, { headers })
					.then((res) => {
						setAllItem(res.data);
						setIsSearchShow(true);
					})
					.catch(function (error) {
						setAllItem(error.response.data.response);
						setIsSearchShow(true);
						// alert("Ups Terjadi Eror : " + error.response.data.status + " -> " + error.response.data.message)
					});
			} else {
			}
		}, 1000)
		return () => clearTimeout(getData)
	}, [query]);


	const getById = (id) => {
		const headers = {
			"Content-Type": "application/json",
			Authorization: `Bearer ${token}`,
		};
		axios
			.get(
				`${process.env.REACT_APP_API_BASE_URL}/warehouse/get/${id}`,
				{ headers }
			)
			.then(async(data) => {
				setName(data.data.response.warehouse_name);
				setAddress(data.data.response.address);
				getProvinsi(data.data.response.province_id);
				setCode(data.data.response.code);
				getKota(data.data.response.city_id);
				setPhone(data.data.response.phone);
				setImage(data.data.response.logo);
				setLong(data.data.response.longitude);
				setLat(data.data.response.latitude);
				setRadius(data.data.response.jarak_absen);
				await onLoadMaps(data.data.response.latitude, data.data.response.longitude)
				setMapCenter({lat: data.data.response.latitude, lng: data.data.response.longitude})
			}) 
			.catch(function (error) {
				console.log(error);
			});
	};

	const getProvinsi = (id) => {
		const headers = {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`
		}
		axios.get(`${process.env.REACT_APP_API_BASE_URL}/province/list`, {
			headers
		})
			.then(data => {
				setProvinces(data.data.response_data);
				setProvince(id);
			})
			.catch(function (error) {
				console.log(error)
			})
	}

	const getKota = (id) => {
		const headers = {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`
		}
		axios.get(`${process.env.REACT_APP_API_BASE_URL}/city/list`, {
			headers
		})
			.then(data => {
				setCitys(data.data.response_data);
				setCity(id);
			})
			.catch(function (error) {
				console.log(error)
			})
	}

	const searchShow = (item) => {
		getById(item.id)
		setItemId(item.id)
		setIsSearchShow(false);
	};

	// useEffect(() => {
	// 	const map = L.map('mapId').setView([defaultLat, defaultLng], 13);
	// 	L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
	// 		attribution: '© OpenStreetMap contributors'
	// 	}).addTo(map);
	// 	apiIsLoaded(map); // Store the map instance
	// }, []);

	useEffect(() => {
		if (lat && long) {
			onLoadMaps(lat, long); // Update map center based on new lat/lng
		}
	}, [lat, long]); // Dependency array - runs when lat or long change

	const onLoadMaps = async (lats, longs) => {
		// console.log("Latitude:", lats, "Longitude:", longs);
		if (onmap) {
			const bounds = new L.LatLngBounds(
				new L.LatLng(lats, longs)
			);
			onmap.fitBounds(bounds);
		}
		if (onmap) {
			onmap.setZoom(14); // Set a default zoom level
		}
	};

	// const onLoadMaps = async (lats, longs) => {
	// 	if (!onmap) {
	// 		console.error("Map instance not available yet.");
	// 		return;
	// 	}
	// 	const bounds = new L.LatLngBounds(new L.LatLng(lats, longs));
	// 	onmap.fitBounds(bounds);
	// 	onmap.setZoom(14);
	// };

    const apiIsLoaded = (map) => {
		console.log("Map instance loaded:", map);
		setOnmap(map);
	};
	
	  const getDataLog = (id) => {
		let filter = {transaction : id,features : "MASTER1",warehouse_id : 0}
		const data = filter;
		const headers = {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`
		  }
		axios.post(`${process.env.REACT_APP_API_BASE_URL}/riwayat-data/info`, data, { headers
		})
		.then(data => {
			setDataLog(data.data.response);
            setModal1(!modal1);

		})
		  .catch(function (error) {
			setGagalAlert(error.response.data.message);
		  })
	  }

	  const setGagalAlert = (id) => {
		setAlert(
		<SweetAlert danger showConfirm confirmBtnText="Ok" title={id} onCancel={hideAlert} onConfirm={hideAlert}/>
			);
		};
		
		const hideAlert = () => { setAlert(null)};

	return (
		<>
		{alert}
		{cabang && cabang === "1" ? (
			<div>
				<SimpleHeader name="Cabang" parentName="Master" />
				<Container className="mt--6" fluid>
					<Row>
						<div className="col">
							<Card className="position-sticky boxShadow" style={{ top: 0, zIndex: "5" }}>
								<CardBody className="ml-3 pb-0">
									<Row md="12">
										<Col md="5 d-lg-block d-none">
											<Button onClick={() => setPage(1)} disabled={page === 1} color="danger" type="button">
												First
											</Button>
											<Button onClick={() => setPage((page) => page - 1)} disabled={page === 1} color="success" type="button">
											<i className="fa fa-angle-left" /> Prev
											</Button>
											<Button onClick={() => setPage((page) => page + 1)} disabled={page >= totalPages} color="success" type="button">
												Next <i className="fa fa-angle-right" />
											</Button>
											<Button onClick={() => setPage(totalPages)} disabled={page == totalPages} color="warning" type="button">
												End
											</Button>
										</Col>
										<Col md="3 ">
											<FormGroup row>
												<Col sm={10}><Input placeholder="Search Cabang" type="search" value={query} onChange={(e) => setQuery(e.target.value)}/></Col>
												<Col sm={2}></Col>
											</FormGroup>
										</Col>
										<Col md="4 d-lg-block d-none">
											<div style={{ textAlign: "right" }}>
												{Update && Update === "1" && (
												<Link className="btn btn-info" to={redirectPrefix + itemId}><i className="fas fa-user-edit" /> Edit</Link>
												)}
												{Create && Create === "1" && (
												<UncontrolledDropdown nav>
													<DropdownToggle color="danger" ><i className="fas fa-plus" /> Tambah</DropdownToggle>
													<DropdownMenu right>
														<Link hidden to="/admin/warehouse/pusat/create"><DropdownItem><span>Pusat</span></DropdownItem></Link>
														{/* <Link to="/admin/warehouse/gudang-pusat/create"><DropdownItem><span>Gudang Pusat</span></DropdownItem></Link> */}
														<Link to="/admin/warehouse/region/create"><DropdownItem><span>Region</span></DropdownItem></Link>
														<Link hidden to="/admin/warehouse/gudang-region/create"><DropdownItem><span>Gudang Region</span></DropdownItem></Link>
														<Link to="/admin/warehouse/toko/create"><DropdownItem><span>Toko</span></DropdownItem></Link>
														<Link to="/admin/warehouse/gudang-toko/create"><DropdownItem><span>Gudang Toko</span></DropdownItem></Link>
													</DropdownMenu>
												</UncontrolledDropdown>
												)}
											</div>
										</Col>
									</Row>
								</CardBody>
							</Card>
							{isSearchShow && query && (
								<Card className="boxShadow" style={{ maxHeight: "15.5rem", overflowY: "scroll", paddingTop: "1rem", position: "relative" }}>
									<div style={{ position: "absolute", top: "2.5px", right: "1rem", cursor: "pointer", fontSize: "2rem" }}>
										<i className="fas fa-window-close text-danger" onClick={() => setIsSearchShow(false)}></i>
									</div>
									<span className="text-center mb-3">
										<b>Pencarian berdasarkan:</b> {query}
									</span>
									{allItem?.response ? (
										allItem.response.map((item) => (
											<CardBody key={item.id} className="bgSearch border-bottom p-2 mb-2" onClick={() => (searchShow(item),setQuery(""))} style={{ minHeight: 'auto', padding: "1rem" }}>
												<div><b>Nama :</b> {item.warehouse_name}</div>
											</CardBody>
										))
									) : (
										<div className="text-center mb-3 text-danger">Cabang "{query}" tidak ada bosku!</div>
									)}
								</Card>
							)}
							<CardBody>
								<Card className="bg-secondary shadow">
									<CardHeader>
										<div
											style={{ display: "flex", justifyContent: "space-between" }}
										>
											<h3>Lokasi {name}</h3>
											<div style={{ textAlign: "rigth" }}><Button color="warning" onClick={() => (getDataLog(itemId))}><i className="fa fa-info-circle" aria-hidden="true"></i></Button></div>
										</div>
									</CardHeader> 
									<CardBody>
										<Row md="16">
											<Col md="4">
												<FormGroup row>
													<Label className="form-control-label" for="exampleEmail" sm={2}>lat</Label>
													<Col sm={9}>
														<Input disabled autoComplete="off" className="form-control-alternative" type="number" pattern='[0-9]{0,5}' name="Phone" placeholder="Masukan Lat" value={lat} onChange={(e) => {setLat(e.target.value);}}/>
													</Col>
												</FormGroup>
											</Col>
											<Col md="4">
												<FormGroup row>
													<Label className="form-control-label" for="exampleEmail" sm={3}>Lng</Label>
													<Col sm={9}>
														<Input disabled autoComplete="off" className="form-control-alternative" type="number" pattern='[0-9]{0,5}' name="Phone" placeholder="Masukan Long" value={long} onChange={(e) => { setLong(e.target.value);}}/>
													</Col>
												</FormGroup>
											</Col>
											<Col md="4">
												<FormGroup row>
													<Label className="form-control-label" for="exampleEmail" sm={3}>Radius</Label>
													<Col sm={9}>
														<Input disabled autoComplete="off" className="form-control-alternative" type="number" pattern='[0-9]{0,5}' name="Phone" placeholder="Masukan Radius" value={radius} onChange={(e) => {setRadius(e.target.value);}}/>
													</Col>
												</FormGroup>
											</Col>
											<Col md="4">
											</Col>
										</Row>
										{/* lat : {lat} .  long : {long} mapscenter :  */}
											<div style={{ height: '45vh', width: '100%' }}>
												<MapContainer center={mapCenter} zoom={14} style={{ height: '100%', width: '100%' }} whenCreated={({ map }) => apiIsLoaded(map)}>
													<TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
													<Marker position={mapCenter} icon={masukIcon}>
													</Marker>
												</MapContainer>
											</div>
										{/* </div> */}
									</CardBody>
								</Card>
								{/* </Col>
                        </Row> */}
							</CardBody>
							<CardBody>
								<Row md="12">
									<Col md="7">
										<Card className="bg-secondary shadow">
											<CardHeader className="bg-white border-0">
												<h3>Cabang</h3>
											</CardHeader>
											<CardBody>
												<FormGroup row>
													<Label className="form-control-label" for="exampleEmail" sm={4}>Kode</Label>
													<Col sm={7}>
														<Input disabled className="form-control-alternative" type="text" name="itemCode" placeholder="Masukan Kode Item"value={code} onChange={(e) => {setCode(e.target.value);}}/>
													</Col>
												</FormGroup>
												<FormGroup row>
													<Label className="form-control-label" for="exampleEmail" sm={4}>
														Nama
													</Label>
													<Col sm={7}>
														<Input disabled className="form-control-alternative" type="text" name="itemCode" placeholder="Masukan Nama"value={name}onChange={(e) => {setName(e.target.value);}}/>
													</Col>
												</FormGroup>
												<FormGroup row>
													<Label className="form-control-label" for="exampleEmail" sm={4}>
														Alamat
													</Label>
													<Col sm={7}>
														<Input disabled className="form-control-alternative" type="textarea" name="barcode" rows="4" placeholder="Masukan Barcode" value={address} onChange={(e) => {setAddress(e.target.value);}}/>
													</Col>
												</FormGroup>
												<FormGroup row>
													<Label className="form-control-label" for="exampleEmail" sm={4}>
														Provinsi
													</Label>
													<Col sm={7}>
														<Input disabled className="form-control-alternative" name="Supplier" type="select" value={province} onChange={(e) => {setProvince(e.target.value);}}>
															<option value="">Pilih Provinsi</option>
															{provinces.map((suppliers, key) => {
																return (
																	<option key={key} value={suppliers.id}> {suppliers.name}</option>
																);
															})}
														</Input>
													</Col>
												</FormGroup>
												<FormGroup row>
													<Label className="form-control-label" for="exampleEmail" sm={4}>
														Kota
													</Label>
													<Col sm={7}>
														<Input disabled className="form-control-alternative" name="Supplier" type="select" value={city} onChange={(e) => {setCity(e.target.value);}}>
															<option value="">Pilih Kota</option>
															{citys.map((kota, key) => {
																return (
																	<option key={key} value={kota.id}> {kota.name}</option>
																);
															})}
														</Input>
													</Col>
												</FormGroup>
												<FormGroup row>
													<Label className="form-control-label" for="exampleEmail" sm={4}>
														Kontak
													</Label>
													<Col sm={7}>
														<Input disabled className="form-control-alternative" type="text" name="Berat" placeholder="Masukan phone" value={phone} onChange={(e) => {setPhone(e.target.value);}}/>
													</Col>
												</FormGroup>
											</CardBody>
										</Card>
									</Col>
									<Col md="5">
										<Card className="bg-secondary shadow">
											<CardHeader className="bg-white border-0">
												<div style={{ display: "flex", justifyContent: "space-between" }}>
													<h3 >Gambar</h3>
													<div style={{ textAlign: 'right' }}>
														<Link className="btn btn-warning" to={redirectPrefix1 + itemId}><i className="fas fa-pen" /></Link>
													</div>
												</div>
											</CardHeader>
											<CardBody>
                                                <FormGroup row>
                                                    <CardGroup>
                                                        &nbsp;
                                                        &nbsp;
                                                        &nbsp;
                                                        <Row>
                                                            <Col>
                                                            {image === "" ?( <img alt="..." src={"https://i.ibb.co/j5qfxy8/NO-IMAGE-AVAILABLE-2.png"} width="95%"/>):( <img alt="" src={image} width="95%" />)}
                                                            </Col>
                                                        </Row>
                                                    </CardGroup>
                                                </FormGroup>
											</CardBody>
										</Card>
									</Col>
								</Row>
							</CardBody>
						</div>
					</Row>
				</Container>
				<Modal isOpen={modal1} toggle={toggle1} style={{ minWidth: "70%", top: "5%"  }}>
        <ModalHeader toggle={toggle1} className="text-center" align="center"></ModalHeader>
        <ModalBody align="center">
          <h2><b>Log Info</b></h2> 
          <CardBody>
          <ToolkitProvider 
              rowNumber={rowIndex}
              data={datalog}
              keyField="id"
              columns={[
                  {dataField: "no", text: "#", sort: true, page: 1, formatter: (cell, row, index) => {let currentRow = ++index; return currentRow + rowIndex;},},
				  {dataField: "api_hit", text: "Tanggal Update",sort: true},
                  {dataField: "username", text: "PIC",sort: true},
                  {dataField: "lost_data",text: "Data Lama",sort: true},
                  {dataField: "show_data",text: "Data Baru",sort: true},
              ]}
              >
              {(props) => (
                  <div className="py-4 table-responsive">
                  <BootstrapTable
                      remote
                      {...props.baseProps}
                      bootstrap4={true}
                      bordered={false}
                      hover={true}
                  />
                  </div>
              )}
              </ToolkitProvider>
          </CardBody>
          <Button color="secondary" onClick={toggle1}>Kembali</Button>
        </ModalBody>
      </Modal>  
			</div>
		):(
			<Halaman404 />
		)}
		</>
	);
}